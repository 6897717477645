import React from "react";
import { copyClip } from "../../../../utils/copyClip";

export default function CopyProfile({ history }) {
    return (
        <React.Fragment>
            {/* dropdown */}
            <div className="dropdown">
                <div
                    className="dropdown-menu shadowBox"
                    aria-labelledby="triggerisguest"
                >
                    <button
                        className="dropdown-item"
                        onClick={() => copyClip(history)}
                    >
                        {" "}
                        <img src="/images/profile/clip.png" alt="" /> Copy
                        Profile Link
                    </button>
                    {/* <button className="dropdown-item"  > <img src="/images/profile/block.png" alt="" /> Block <span className='font-weight-normal'>@{user?.username}</span></button> */}
                </div>
            </div>
            <button
                className="btn lightHover"
                id="triggerisguest"
                data-toggle="dropdown"
                aria-haspopup="true"
            >
                <img src="/images/profile/vh.png" alt="" />
            </button>
        </React.Fragment>
    );
}
