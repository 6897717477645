import React from 'react'
import { Link } from 'react-router-dom'

function CommingSoon({name}) {

    return (
        <div className="commingsoon">

            <h1 className='pb-3'>{name} is coming soon</h1>

            <img src="/images/oops.png" alt="" />
            <p className='py-5'>
                The amazing feature is in the works and will be launching soon.<br/>
                We will surely notify once it goes live.
            </p>

            <Link className="btn" to={-1}>
                Go Back
            </Link>
        </div>
    )
}

export default CommingSoon

