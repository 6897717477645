import React from 'react'

export function Clothe({fill}) {
  return (
      <svg width="85" height="61" viewBox="0 0 95 71" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M91.7511 7.27852C83.3911 5.23498 61.7501 -0.0527276 61.7501 0.000397386C61.7501 2.81832 60.2487 5.52083 57.5763 7.51341C54.9039 9.50598 51.2794 10.6254 47.5001 10.6254C43.7207 10.6254 40.0962 9.50598 37.4238 7.51341C34.7514 5.52083 33.2501 2.81832 33.2501 0.000397386C33.2501 -0.0527276 11.6091 5.23498 3.24432 7.27852C2.2966 7.51015 1.47189 7.96001 0.888892 8.56335C0.305898 9.1667 -0.0053087 9.89241 6.85273e-05 10.636V23.7969C0.000267838 24.3348 0.164831 24.8657 0.48127 25.3492C0.797708 25.8326 1.2577 26.256 1.82634 26.5871C2.39498 26.9183 3.05732 27.1485 3.76309 27.2602C4.46886 27.372 5.1995 27.3625 5.89957 27.2323L19.0001 24.7921V67.2921C19.0001 68.2314 19.5005 69.1322 20.3913 69.7964C21.2821 70.4606 22.4903 70.8337 23.7501 70.8337H71.2501C72.5098 70.8337 73.718 70.4606 74.6088 69.7964C75.4996 69.1322 76.0001 68.2314 76.0001 67.2921V24.7921L89.1006 27.2323C89.8006 27.3625 90.5313 27.372 91.2371 27.2602C91.9428 27.1485 92.6052 26.9183 93.1738 26.5871C93.7424 26.256 94.2024 25.8326 94.5189 25.3492C94.8353 24.8657 94.9999 24.3348 95.0001 23.7969V10.636C95.0051 9.89197 94.6932 9.16593 94.1093 8.56253C93.5254 7.95913 92.6997 7.50953 91.7511 7.27852Z"
              fill={fill} />
      </svg>
  )
}
 
