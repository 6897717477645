import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getSuggestedFrends } from "../store/Community";
import { getArticles } from "../store/Blog";
/*eslint-disable*/

function LoadBanner({ type }) {
    const dispatch = useDispatch();
    const [isload, setisload] = useState(false);
    const loadData = () => {
        switch (type) {
            case "getSuggestedFrends":
                dispatch(getSuggestedFrends());
                break;
            case "trendingNews":
                dispatch(getArticles());
                break;

            default:
                break;
        }
    };

    if (isload) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <div
                    className="spinner-border text-primary spinner-border-sm"
                    role="status"
                >
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    } else
        return (
            <img
                src="/images/reload.png"
                alt=""
                className="link"
                onClick={() => loadData()}
            />
        );
}

export default LoadBanner;
