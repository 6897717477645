import React from 'react'
import { Link } from 'react-router-dom'

function PageNotFound() {
    return (
        <div className="commingsoon pt-0 mt-5">

            <h1 className='pb-3'>Oops!</h1>

            <img src="/images/oops.png" alt="" />
            <p className='py-5'>
              Page Not Found<br />
               <h1>404 page</h1>
            </p>

            <Link className="btn" to={-1}>
                Go Back
            </Link>
        </div>
    )
}

export default PageNotFound

