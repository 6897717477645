import React, { useState } from 'react'
import { voteReq } from '../../../utils/request';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getLeaderSingleBoard } from '../../../store/LeaderBoard';
import UsersVote from './UsersVote';
import BodyPost from '../../Community/components/Post/BodyPost';
// import UsersVote from './UsersVote';
/*eslint-disable*/
function VoteSummary({ data, newRoutes ,auth}) {
    const [value] = useState(null);
    const [isload, setLoading] = useState(false);
    const dispatch = useDispatch()

    const handleSubmit = async () => {
        setLoading(true)
        //close modal toggle

        const res = await voteReq(value, newRoutes);

        toast.update("toastId.current", {
            render: "Your message...",
            type: "success",
            isLoading: "false"
        });
        if (res && res.data) {
            setLoading(false)
            dispatch(getLeaderSingleBoard(newRoutes));
            // ModalClose("loadPopup")
            // window.location.reload()
        }
        else if (res && res?.response) {
            toast.error(res?.response?.data?.message)
            setLoading(false)
        }
        else {
            toast.info('Oops something went wrong')
            setLoading(false)
        }

    }
    const filteredVotes = data?.votes.filter(vote => vote.user_id === auth?.id);
// console.debug(data)

    return (
        <div className='vote_summary'>
            <img src={data?.user?.profile?.profile_photo} alt="" />
            <h5>{data?.user?.profile?.first_name} {data?.user?.profile?.last_name}</h5>
            <span>{data?.user?.username}</span>
            <div>
            <h5 className='my-4'>{data?.leaderboard?.title} </h5>
                <p>
                     <BodyPost body={data?.body} />
                </p>
              {data?.leaderboard?.phase !== 'result' && <div>
                    {filteredVotes?.length ?<button className='btn  mr-2 btn-vote ' onClick={() => handleSubmit()}><i className="fa fa-thumbs-o-down" aria-hidden="true"></i> Unvote</button>:
                   <button className='btn  mr-2 btn-vote ' onClick={() => handleSubmit()}><i className="fa fa-thumbs-o-up" aria-hidden="true"></i> Vote</button>}
                    <button className='btn btn-transparent'>{data?.votes?.length} Votes</button>
                </div>}
            </div>

            {data?.votes?.length ? <h5 className='fanText'>Your top fans right now </h5> : null}
            <div className="row">
                {data?.votes && data?.votes?.slice(0,12)?.map((item, key) => <UsersVote data={item} key={key} />)}

            </div>
        </div>
    )
}

export default VoteSummary
