import React from 'react'
import moment from 'moment';

function UsersVote({ data }) {

    return (
        <div className='UsersVote shadow col-md-3 mt-2'>
            <div>
                <img src={data?.user?.profile?.profile_photo} alt="" />
            </div>
            <div>
                <h6 className="name">{data?.user?.username}</h6>
                <small className="username">{data?.user?.username}</small>
            </div>
            <div>
                <small className="time">{moment(data?.created_at).fromNow()}</small>
            </div>

        </div>
    )
}

export default UsersVote
