import axios from 'axios'
import { config } from './config'
import { qParams } from './getQueryParams';

const { Api, Header, HeaderMultipart } = config;


// auth request
export const signupReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/auth/signup`, req);
        return res
    } catch (error) {
        return error
    }
}

export const resendTwoFactorAuthentication = async (req) => {
    try {
        const res = await axios.post(`${Api}/auth/2fa/otp/resend`, req);
        return res
    } catch (error) {
        return error
    }
}

export const verifyTwoFactorAuthentication = async (req) => {
    try {
        const res = await axios.post(`${Api}/auth/2fa/otp/verify`, req);
        return res
    } catch (error) {
        return error
    }
}

export const signinReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/auth/signin`, req);
        return res
    } catch (error) {
        return error
    }

}
export const deactivateAccountReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/auth/deactivate-account`, req, Header);
        return res
    } catch (error) {
        return error
    }

}
export const signoutReq = async (req) => {
    try {
        window.localStorage.clear()

        await axios.post(`${Api}/auth/signout`, req, Header);
        window.location.reload()

    } catch (error) {
        window.localStorage.clear()
        window.location.href = '/auth/signin';
        return error
    }
}

export const resetPasswordReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/auth/reset-password?token=${req.token}&email=${req.email}`, req);
        return res
    } catch (error) {
        return error
    }
}

export const twoFactorReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/auth/2fa/toggle`, req, Header);
        return res
    } catch (error) {
        return error
    }
}

export const changePasswordReq = async (req) => {
    try {

        const res = await axios.post(`${Api}/auth/change-password`, req, Header);
        return res
    } catch (error) {
        return error
    }
}

export const updateUsernameReq = async (req) => {
    try {

        const res = await axios.post(`${Api}/auth/update-username`, req, Header);
        return res
    } catch (error) {
        return error
    }
}

export const forgotPasswordReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/auth/forgot-password`, req);
        return res
    } catch (error) {
        return error
    }
}
export const updatProfileReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/auth/update-profile`, req, Header);
        return res
    } catch (error) {
        return error
    }
}
export const updatProfilePhotoReq = async (req) => {
    try {

        const res = await axios.post(`${Api}/auth/upload-profile-photo`, req, HeaderMultipart);
        return res
    } catch (error) {
        return error
    }
}
export const updateCoverPhotoReq = async (req) => {
    try {

        const res = await axios.post(`${Api}/auth/upload-cover-photo`, req, HeaderMultipart);
        return res
    } catch (error) {
        return error
    }
}

const links = [
    'community',
    "banter",
    "leaderboard",
    "chat",
    "profile"
]

export const getUserReq = async (req) => {
    try {
        const res = await axios.get(`${Api}/auth/user-account`, Header);
        return res
    } catch (error) {
        if (error.response.status === 401 && links.includes(window.location.pathname)) {
            localStorage.removeItem("token")
            window.location.href = "/auth/signin"
        }
        return error
    }
}


// blog request
export const getBlogReq = async (req) => {
    try {
        const res = await axios.get(`${Api}/categories`);
        return res
    } catch (error) {
        return error
    }
}
export const getSingleBlogReq = async (req) => {
    try {
        const res = await axios.get(`${Api}/article/find/${req}`);
        return res
    } catch (error) {
        return error
    }
}
export const getArticlesReq = async (req) => {
    try {
        const res = await axios.get(`${Api}/articles`);
        return res
    } catch (error) {
        return error
    }
}
export const addCommentReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/comment/create`, req, Header);
        return res
    } catch (error) {
        return error
    }
}
export const getCommentsReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/comment/create`, req, Header);
        return res
    } catch (error) {
        return error
    }
}
export const addLikeReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/like/create/${req.article_id}`, req, Header);
        return res
    } catch (error) {
        return error
    }
}
export const addReplyReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/reply/create`, req, Header);
        return res
    } catch (error) {
        return error
    }
}
export const deleteReplyReq = async (replyId, req) => {
    try {
        const res = await axios.post(`${Api}/community/reply/delete/${replyId}`, req, Header);
        return res
    } catch (error) {
        return error
    }
}


// community
export const createPostReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/community/post/create`, req, Header);
        return res
    } catch (error) {
        return error
    }
}

export const getPostReq = async (req) => {
    try {
        const q = qParams("q", window.location.search);
        const id = qParams("id", window.location.search);
        const res = await axios.get(`${Api}/community/posts?keyword=${q}&id=${id}`, Header);
        return res
    } catch (error) {
        return error
    }
}
export const getSelectedPostReq = async (id) => {
    try {
        const res = await axios.get(`${Api}/community/post/find/${id}`, Header);
        return res
    } catch (error) {
        return error
    }
}


export const deletePostReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/community/post/delete/${req?.id}`, null, Header);
        return res
    } catch (error) {
        return error
    }
}

// suggested Frends
export const getSuggestedFrendsReq = async (req) => {
    try {
        const res = await axios.get(`${Api}/community/suggested-users`, Header);
        return res
    } catch (error) {
        return error
    }
}


// follows
export const followReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/community/user/follow`, req, Header);
        return res
    } catch (error) {
        return error
    }
}

export const followersReq = async (req) => {
    try {
        const res = await axios.get(`${Api}/community/user/my/followers`, Header);
        return res
    } catch (error) {
        return error
    }
}

export const followingReq = async (req) => {
    try {
        const res = await axios.get(`${Api}/community/user/my/followings`, Header);
        return res
    } catch (error) {
        return error
    }
}

// community like
export const addCommunityLikeReq = async (req, payload) => {
    try {
        const res = await axios.post(`${Api}/community/like/create/${req}`, req, Header);
        return res
    } catch (error) {
        return error
    }
}

export const addCommunityCommentsReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/community/comment/create`, req, Header);
        return res
    } catch (error) {
        return error
    }
}
export const deleteCommunityCommentsReq = async (commentId, req) => {
    try {
        const res = await axios.post(`${Api}/community/comment/delete/${commentId}`, req, Header);
        return res
    } catch (error) {
        return error
    }
}


// save Post
export const savePostReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/savedpost/create`, req, Header);
        return res
    } catch (error) {
        return error
    }
}

export const pinPostReq = async (payload, id) => {
    try {
        const res = await axios.post(`${Api}/community/post/pin/${id}`, payload, Header);
        return res
    } catch (error) {
        // console.debug(error)
        return error
    }
}

// keywords
export const keywordsReq = async () => {
    try {
        const res = await axios.get(`${Api}/community/posts/trends`, Header);
        return res
    } catch (error) {
        return error
    }
}

// saved Post
export const getSavePostReq = async (req) => {
    try {
        const res = await axios.get(`${Api}/savedposts`, Header);
        return res
    } catch (error) {
        return error
    }
}
// User Profile Post
export const getProfileReq = async (email) => {
    try {
        const res = await axios.get(`${Api}/community/find-user?keyword=${email}`, Header);
        return res
    } catch (error) {
        return error
    }
}

// User Chat
export const postChatReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/community/message/send`, req, Header);
        return res
    } catch (error) {
        return error
    }
}
export const getChatReq = async (req) => {
    try {
        const res = await axios.get(`${Api}/community/message/conversation/${req}`, Header);
        return res
    } catch (error) {
        return error
    }
}

export const getLastConvesationReq = async (req) => {
    try {
        const res = await axios.get(`${Api}/community/message/recent-conversations`, Header);
        return res
    } catch (error) {
        return error
    }
}


export const postContactReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/contact-us`, req, Header);
        return res
    } catch (error) {
        return error
    }
}


// leaderboard
export const getLeaderBoardParticipateCheckReq = async (req) => {
    try {
        const res = await axios.get(`${Api}/leaderboard-challenge/check`, Header);
        return res
    } catch (error) {
        return error
    }
}

export const getLeaderBoardReq = async (req) => {
    try {
        const res = await axios.get(`${Api}/leaderboard-challenges`, Header);
        return res
    } catch (error) {
        return error
    }
}
export const getLeaderBoardSingleReq = async (id) => {
    try {
        const res = await axios.get(`${Api}/leaderboard-challenge/find/${id}`, Header);
        return res
    } catch (error) {
        return error
    }
}


export const voteReq = async (req, id) => {
    try {
        const res = await axios.post(`${Api}/leaderboard/vote/create/${id}`, req, Header);
        return res
    } catch (error) {
        return error
    }
}

export const getRankReq = async () => {
    try {
        const res = await axios.get(`${Api}/leaderboard-challenges/top-ranking`, Header);
        return res
    } catch (error) {
        return error
    }
}

export const getLastWeekTopReq = async () => {
    try {
        const res = await axios.get(`${Api}/leaderboard-challenges/last-week/top-ranking`, Header);
        return res
    } catch (error) {
        return error
    }
}




// banter
export const createBanterReq = async (payload, id) => {
    try {
        const res = await axios.post(`${Api}/bant/create`, payload, Header);
        return res
    } catch (error) {
        return error
    }
}
export const deleteBantsReq = async (id) => {
    try {
        const res = await axios.post(`${Api}/bant/delete/${id}`, null, Header);
        return res
    } catch (error) {
        return error
    }
}
export const getAllBantsReq = async (payload) => {
    try {
        const res = await axios.get(`${Api}/bants?${payload}`, Header);
        return res
    } catch (error) {
        return error
    }
}
export const getMyBantsReq = async (payload) => {
    try {
        const res = await axios.get(`${Api}/my-bants?${payload}`, Header);
        return res
    } catch (error) {
        return error
    }
}
export const viewBanterReq = async (id) => {
    try {
        const res = await axios.post(`${Api}/view/bant/${id}`, null, Header);
        return res
    } catch (error) {
        return error
    }
}
export const getSingleBantsReq = async (id) => {
    try {
        const res = await axios.get(`${Api}/bant/find/${id}`, Header);
        return res
    } catch (error) {
        return error
    }
}
export const addBanterLikesReq = async (req, id) => {
    try {
        const res = await axios.post(`${Api}/community/like/create/${id}`, req, Header);
        return res
    } catch (error) {
        return error
    }
}
export const addBanterCommentsReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/comment/create`, req, Header);
        return res
    } catch (error) {
        return error
    }
}
// Notifications
export const getNotification = async (id) => {
    try {
        const res = await axios.get(`${Api}/notifications`, Header);
        return res
    } catch (error) {
        return error
    }
}
export const readNotification = async (id) => {
    try {
        const res = await axios.post(`${Api}/notifications/read-all`, {}, Header);
        return res
    } catch (error) {
        return error
    }
}
export const unReadNotification = async (id) => {
    try {
        const res = await axios.get(`${Api}/notifications/unread-count`, Header);
        return res
    } catch (error) {
        return error
    }
}



// /Draft
export const getDraftReq = async () => {
    try {
        const res = await axios.get(`${Api}/drafts`, Header);
        return res
    } catch (error) {
        return error
    }
}

export const createDraftReq = async (req) => {
    try {
        const res = await axios.post(`${Api}/draft/create`, req, Header);
        return res
    } catch (error) {
        return error
    }
}
export const deleteDraftReq = async (req, id) => {
    try {
        const res = await axios.post(`${Api}/draft/delete/${id}`, req, Header);
        return res
    } catch (error) {
        return error
    }
}

