import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BanterForm from "./BanterForm";
import { useNavigate } from "react-router-dom";
import { filterBanter } from "../../../store/Banter";
import BantForm from "./BantForm";
import { HiBarsArrowUp } from "react-icons/hi2";

function BanterHeader() {
    const auth = useSelector((state) => state.auth.user);
    const [search, setSearch] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleSearch = (e) => {
        e.preventDefault();
        navigate(`/banter?q=${search}`);
    };
    return (
        <div className="banterHeader shadow bg-white mb-4 ">
            <h1>Banter Rooms</h1>
            <p>
                Have Fun, Create and Engage in Conversations around your
                favourite teams, events and players.
            </p>
            <BantForm auth={auth} />

            {/* search Box */}
            <form
                className="form-inline my-2 my-lg-0 d-none  d-flex justify-content-between "
                onSubmit={(e) => handleSearch(e)}
            >
                <div className="searchBox">
                    <img
                        src="/images/nav/search.png"
                        className="search"
                        alt=""
                    />
                    <input
                        className="form-control mr-sm-2 "
                        type="search"
                        placeholder="Search Bants..."
                        onChange={(e) => {
                            setSearch(e.target.value);
                            dispatch(filterBanter(search));
                        }}
                    />
                    <button className="btn btn-filter btn-blue d-md-inline d-none">
                        {/* <i className="pi pi-bars"></i> */}
                        <HiBarsArrowUp size="28" />
                    </button>
                </div>

                {/* <div>
                    <button className="btn btn-search btn-blue ">
                        <i
                            className="fa fa-search d-inline d-md-none"
                            aria-hidden="true"
                        ></i>
                        <span className="d-md-inline d-none">
                            Search Banter
                        </span>
                    </button>
                </div> */}
            </form>

            <BanterForm auth={auth} />
        </div>
    );
}

export default BanterHeader;
