
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { addCommunityCommentsReq } from '../../../../utils/request';
// import { toast } from 'react-toastify';
import { gePost } from '../../../../store/Community';
import { getSavePost } from '../../../../store/Community/index';
function CommentForm({ user, item }) { 
    const dispatch = useDispatch()
    const [value, setValue] = useState({ body: "", post_id: item?.id });
    const [isload, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        if (e !== "btn") e.preventDefault();
        setLoading(true)
        const res = await addCommunityCommentsReq(value);
        if (res && res.data) {
            setLoading(false)
            dispatch(gePost())
            dispatch(getSavePost())
            setValue({ ...value, body: "" })
        }
        else if (res && res?.response) {
            setLoading(false)
            setValue({ ...value, body: "" })

        }
        else {
            setLoading(false)
            setValue({ ...value, body: "" })

        }

    }

    // console.debug(item)
    return (
        <div>
            <form className="inputBoxComment row m-0 p-0 " onSubmit={(e) => handleSubmit(e)}>
                <div className="d-flex align-items-center">
                    <img src={user?.profile?.profile_photo ?? `/images/emeka.png`} alt="" className="profile-avatar" />
                </div>
                <textarea value={value?.body} cols={1} rows={1} placeholder='Write a comment...' className='comment-input' onChange={(e) => setValue({ ...value, body: e.target.value })} ></textarea>
                <div className="sendMessageBox align-items-center">
                    {isload ? <i className="fa fa-spinner fa-pulse fa-fw ml-2 " aria-hidden="true" ></i> :
                        <i className="fa fa-paper-plane-o ml-2 " aria-hidden="true" onClick={() => handleSubmit("btn")}></i>}
                </div>
            </form>
        </div>
    )
}

export default CommentForm
