import React from "react";
import { ShareSocial } from "react-share-social";

function ShareBtn() {
    // const youtubeURL = "https://www.youtube.com/@soccernity";
    // const facebookURL = "https://www.facebook.com/soccernity";
    // const instagramURL = "https://instagram.com/official_soccernity";
    // const tiktokURL = "https://www.tiktok.com/@soccernity";
    // const twitterURL = "https://twitter.com/soccernity";
    // const shareVia = (url) => window.open(url, "_blank");

    const style = {
        root: {
            background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
            borderRadius: 3,
            border: 0,
            boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
            color: "white",
        },
        copyContainer: {
            border: "1px solid blue",
            background: "rgb(0,0,0,0.7)",
        },
        title: {
            color: "aquamarine",
            fontStyle: "italic",
        },
    };

    const share = (
        <ShareSocial
            url={window.location.href}
            socialTypes={[
                "facebook",
                "twitter",
                "whatsapp",
                "linkedin",
                "telegram",
            ]}
            style={style}
        />
    );

    return (
        <div className="makeStyles-container-1">
            <div className="makeStyles-iconContainer-3">{share}</div>
        </div>
    );
}

export default ShareBtn;
