import React from 'react'
import { useNavigate } from 'react-router-dom'

function EntriesHeader() {
    const navigate=useNavigate()
  return (
      <div className='EntriesHeader'>
          <div className='py-3'>
              <button className='navigator btn btn-sm p-0 btn-transparent' onClick={()=>navigate(-1)}><img src="/images/back.png" alt="" /></button>
          </div>
          <h3>Entries</h3>
          <p>Vote your preferred video entry to qualify for the contest prizes. You can only vote once and for one entry.</p>
    </div>
  )
}

export default EntriesHeader
