import React from 'react'
function FirstLoading() {

        return (
            <div className='FirstLoading'>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-white spinner-border-sm"
                        role="status">
                    </div>
                </div>
            </div>
        )
}

export default FirstLoading
