import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLastWeekTopReq, getLeaderBoardParticipateCheckReq, getLeaderBoardReq, getLeaderBoardSingleReq, getRankReq } from "../../utils/request";

export const getLeaderBoardParticipateCheck = createAsyncThunk(
    'leaderboard/getLeaderBoardParticipateCheck',
    async () => {
        const res = await getLeaderBoardParticipateCheckReq();
        return res.data;
    }
)
export const getLeaderBoard = createAsyncThunk(
    'leaderboard/getLeaderBoardReq',
    async () => {
        const res = await getLeaderBoardReq();

        return res.data;
    }
)
export const getLeaderSingleBoard = createAsyncThunk(
    'leaderboard/getLeaderSingleBoard',
    async (id) => {
        const res = await getLeaderBoardSingleReq(id);
        return res.data;
    }
)

export const getRank = createAsyncThunk(
    'leaderboard/getRank',
    async () => {
        const res = await getRankReq();
        // console.debug(res)
        return res.data?.leaderBoardChallenges;
    }
)
export const getLastWeekTop = createAsyncThunk(
    'leaderboard/LastWeekTop',
    async () => {
        const res = await getLastWeekTopReq();
        return res.data?.leaderBoardChallenges;
    }
)

export const leaderboardSlice = createSlice(
    {
        name: "leaderboard",
        initialState: {},
        leaderboard_challange: "",
        leaderboard_single_challange: "",
        topRank: "",
        LastWeekTop: "",
        paticipateCheck: "",
        current_leaderBoard: "",
        reducers: {
        },
        extraReducers: (builder) => {
            builder.addCase(getLeaderBoardParticipateCheck.fulfilled, (state, action) => {
                state.paticipateCheck = action.payload;
            })
            builder.addCase(getLeaderBoard.fulfilled, (state, action) => {
            
                state.leaderboard_challange =  action.payload.leaderBoardChallenges;
                state.current_leaderBoard = action.payload.leaderBoard;
            })
            builder.addCase(getLeaderSingleBoard.fulfilled, (state, action) => {
                state.leaderboard_single_challange = action.payload;
            })
            builder.addCase(getRank.fulfilled, (state, action) => {
                state.topRank = action.payload;
            })
            builder.addCase(getLastWeekTop.fulfilled, (state, action) => {
                state.LastWeekTop = action.payload;
            })
        },
    });


// export const { } = leaderboardSlice.actions;

export default leaderboardSlice.reducer;
