import React  from 'react'
import ChatsMessage from './ChatContainerComponents/ChatsMessage'
import ChatsBox from './ChatContainerComponents/ChatsBox';



function ChatContainerDesktop() {

    return (
        <div className='ChatsContainer row m-0'>
            <ChatsBox  />
            <ChatsMessage  />


        </div>
    )
}

export default ChatContainerDesktop

