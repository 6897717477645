import React from "react";
import { Link } from "react-router-dom";
import { footer, footerlinks } from "../../DB";
import moment from "moment";
function Footer() {
    return (
        <footer>
            <div className="my-3  pb-3">
                <img src="/images/footer/logo.svg" alt="" />
            </div>

            <div className="text-center my-5">
                {footerlinks &&
                    footerlinks?.map((item, key) => (
                        <a
                            href={item.url}
                            key={key}
                            target="_blank"
                            rel="noreferrer"
                            className="d-inline mx-3 mb-3 "
                        >
                            <img src={item.img} alt="" className="mb-4 " />
                        </a>
                    ))}
            </div>
            <div className="footerLinks d-none d-md-flex justify-content-center">
                {footer.map((item, key) => (
                    <Link to={item.link} key={key}>
                        <h5 className="d-flex align-items-center">
                            <div className="dot"></div>{" "}
                            <span className="ml-1">{item.name}</span>
                        </h5>
                    </Link>
                ))}
            </div>

            <ul className="p-0 m-0 d-block d-md-none text-center footer-mobile">
                {footer.map((item, key) => (
                    <li key={key}>
                        <Link to={item.link}>{item.name}</Link>
                    </li>
                ))}
            </ul>
            <p className="text-secondary my-3 ">
                71-75 Shelton Street, Covent Garden, <br />
                London, United Kingdom <br />
                <i className="pi pi-phone"></i> Tel: {"+447939596462"}
            </p>
            <div className="copyright col-md-4 col-12 m-auto mt-4">
                <small>
                    Copyright © {moment(Date.now()).format("YYYY")} Soccernity
                    All rights reserved. The information contained in Soccernity
                    may not be published, broadcast, rewritten, or redistributed
                    without the prior written authority of Soccernity
                </small>
            </div>
        </footer>
    );
}

export default Footer;
