import React from 'react'
import BodyPost from '../../Community/components/Post/BodyPost';

function SideTextLeaderBoard({ current_leaderBoard }) {
    return (
        <div>
            <h1>Contest Leaderboard.</h1>

            <p>
                <h6>Instructions:</h6>

                {(
                    <BodyPost body={current_leaderBoard?.public_description} />
                ) ?? <BodyPost body={current_leaderBoard?.description} />}
            </p>
        </div>
    );
}

export default SideTextLeaderBoard
