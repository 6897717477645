import React, { useEffect } from 'react'
import BlogCard from '../../../components/BlogCards'
import { useDispatch, useSelector } from 'react-redux';
import { getBlog } from '../../../store/Blog';
import Footer from '../../../layouts/Layout/Footer';
/*eslint-disable*/

function TrendingTopics() {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getBlog())
    }, []);

    const blog = useSelector((state) => state.blog?.categories)

    return (
        <div className="TrendingTopics">
            <div className="topic_plank">
                <h2>Trending Topics</h2>
            </div>

            <div className="container-fluid d-none d-md-block blogTrendingTopics">
                <BlogCard blog={blog} length={6} />
            </div>
            <div className="d-block d-md-none blogTrendingTopics">
                <BlogCard blog={blog} length={6} />
            </div>

            <Footer />
        </div>
    );
}

export default TrendingTopics
