import React from "react";
import { aboutUs } from "../../../utils/landingPage";
import Truncate from "react-truncate-html";
/* eslint-ignore */

function AboutUs() {
    return (
        <div className="AboutUs container-fluid">
            <h2>About us</h2>
            <div className="row m-0">
                {aboutUs?.map((item, key) => (
                    <div
                        className="aboutUs_components col-md-4"
                        key={key}
                    >
                        <div className="text-center my-4">
                            <img src={item?.icon} alt="" />
                        </div>
                        <article>
                            <Truncate
                                lines={100}
                                dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                }}
                                breakWord={true}
                                responsive={true}
                            />
                            {key === 1 && (
                                <div
                                    style={{ width: "100%" }}
                                    className="pt-4 mt-5 rounded-lg"
                                >
                                    <iframe
                                        width="100%"
                                        height="400"
                                        frameborder="0"
                                        title="Soccernity Address"
                                        marginheight="0"
                                        marginwidth="0"
                                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=71-75%20Shelton%20Street,%20Covent%20Garden+(Soccernity)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                    >
                                        <a href="https://www.maps.ie/population/">
                                            Population Estimator map
                                        </a>
                                    </iframe>
                                </div>
                            )}
                        </article>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AboutUs;
