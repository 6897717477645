import React, { useEffect, useState } from "react";
import { twoFactorReq } from "../../../utils/request"; // Assuming you have an API request for updating user details
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function TwoFA() {
    // State to hold form data
    const auth = useSelector((state) => state.auth?.user);
    const [loading, setLoading] = useState(false); // Loader state
    useEffect(() => {}, []);
    const updateUserDetails = async (e) => {
        e.preventDefault();
        setLoading(true); // Show loader

        // Prepare data to send to the API

        try {
            // Send request to the API
            const response = await twoFactorReq();
            // Handle successful details update (e.g., show success message)
            if (response?.data) {
                toast.success(
                    `2Factor Authentication is ${
                        auth?.is_2fa_enabled === 0 ? "Enabled" : "Disabled"
                    } successfully!`
                );
            } else {
                toast.error(
                    response?.response?.data?.message ??
                        `Failed to ${
                            auth?.is_2fa_enabled === 0 ? "Enabled" : "Disabled"
                        }. Please try again.`
                );
            }
        } catch (err) {
            // Handle error (e.g., show error message)
            toast.error(
                `Failed to ${
                    auth?.is_2fa_enabled === 0 ? "Enabled" : "Disabled"
                }. Please try again.`
            );
        } finally {
            setLoading(false); // Hide loader after request completion
        }
    };

    return (
        <form onSubmit={updateUserDetails}>
            <div className="row col-md-6 col-xl-4 py-4">
                <div className="col-12 py-2">
                    <h5>
                        <b>
                            <b> Two factor Authentication</b>
                        </b>
                    </h5>
                </div>

                <div className="col-12 mb-3">
                    <p>
                        Help protect your account from unauthorized access by
                        requiring a second authentication method in addition to
                        your Soccernity password. You can choose a text message,
                        authentication app, or security key.{" "}
                        <Link className="text-primary" to="/terms">
                            Learn more
                        </Link>
                    </p>
                </div>

                <div className="col-12 mt-1 m-0 p-0">
                    <button
                        className="btn btn-blue ml-2"
                        type="submit"
                        disabled={loading}
                    >
                        {!loading ? (
                            <span>
                                {auth?.is_2fa_enabled === 0
                                    ? "Enable 2FA "
                                    : "Disable 2FA"}
                            </span>
                        ) : (
                            <span>Loading ...</span>
                        )}
                    </button>
                </div>
            </div>
            <hr />
        </form>
    );
}
