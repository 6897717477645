import React from "react";
import { useDispatch } from "react-redux";
import { deleteBantsReq } from "../../../utils/request";
import { getBants, getMyBants } from "../../../store/Banter";


function DropDownListLoadBant({ item, user, textToCopy }) {
    const dispatch = useDispatch();

    const deletePost = async (data) => {
        await deleteBantsReq(data);
        dispatch(getBants());
        dispatch(getMyBants());
    };
    const copyToClipboard = (id) => {
        const textToCopy = `${window.origin}/banter/${id}`;
        navigator.clipboard
            .writeText(textToCopy)
            .then((data) => {
            })
            .catch((error) => {
            });
    };


    return (
        <div className="dropdown-menu shadow-sm" aria-labelledby="triggerId">
            <span
                className="dropdown-item link"
                onClick={() => copyToClipboard(item?.id)}
            >
                Copy Link
            </span>
            {user?.id === item?.user?.id && (
                <span className="dropdown-item link" onClick={() => {}}>
                    Edit Bant
                </span>
            )}
            {user?.id === item?.user?.id && (
                <span
                    className="dropdown-item link"
                    onClick={() => deletePost(item?.id)}
                >
                    Delete Bant
                </span>
            )}
        </div>
    );
}

export default DropDownListLoadBant;
