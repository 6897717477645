import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addCommunityLikeReq, deleteReplyReq } from "../../../../utils/request";
import { gePost } from "../../../../store/Community";
import ReplyForm from "./ReplyForm";
import { getSavePost } from "../../../../store/Community/index";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

function ReplyCard({ item, user, parent_id }) {
    const dispatch = useDispatch();
    const [isload, setLoading] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false); // Modal visibility state
    const [deleteId, setDeleteId] = useState(null); // Track the ID of the comment to be deleted

    const likeFunc = async (id) => {
        setLoading(true);
        const res = await addCommunityLikeReq(id);
        if (res && res.data) {
            dispatch(gePost());
            dispatch(getSavePost());
            setLoading(false);
        } else {
            setLoading(false);
        }
    };
    // Show the delete dialog
    const handleDeleteClick = (replyId) => {
        setDeleteId(replyId);
        setShowDeleteDialog(true); // Show delete modal
    };

    // Delete comment API logic (to be added later)
    const deleteComment = async (replyId) => {
        console.log(`Deleting comment with id: ${replyId}`);
        // Add your delete API call here
        // dispatch(gePost()); // Update posts if necessary
        setShowDeleteDialog(false); // Close modal after delete
        await deleteReplyReq(replyId, {});
    };
    return (
        <div className="CommentsCard mt-b">
            <div className="row-container row my-4">
                <div>
                    <img
                        src={
                            item?.user?.profile?.profile_photo ??
                            `/images/emeka.png`
                        }
                        alt=""
                        className="profile-avatar"
                    />
                </div>
                <div className="body-container  ml-3">
                    <h4 className="username">@{item.user?.username}</h4>
                    <p className="body">{item?.body}</p>
                    <div className="loveLike">
                        {item?.user?.id === user?.id ? (
                            <span className="imgMiniBox ml-3">
                                <span
                                    className="link"
                                    onClick={() => handleDeleteClick(item?.id)}
                                >
                                    <i className="fa fa-trash-o" alt=""></i>
                                </span>
                            </span>
                        ) : (
                            ""
                        )}
                        <span className="imgMiniBox ml-5">
                            <a
                                data-toggle="collapse"
                                href={`#collapseReply${item?.id}`}
                                aria-expanded="false"
                                aria-controls="collapseComment"
                            >
                                <img src="/images/box/reply.png" alt="" />
                            </a>
                            <span>
                                {item?.replies?.length > 0
                                    ? item?.replies?.length
                                    : ""}
                            </span>
                        </span>

                        <span className="imgMiniBox ">
                            {item?.likes?.find(
                                (isLike) => isLike?.user_id === user?.id
                            ) || isload ? (
                                <img
                                    onClick={() => likeFunc(item?.id)}
                                    src="/images/box/lovep.png"
                                    alt=""
                                />
                            ) : (
                                <img
                                    onClick={() => likeFunc(item?.id)}
                                    src="/images/box/love.png"
                                    alt=""
                                />
                            )}
                            {item?.likes?.length > 0 && (
                                <span> {item?.likes?.length}</span>
                            )}
                        </span>
                    </div>
                </div>
            </div>

            {/* Delete confirmation modal */}
            <Dialog
                header="Delete Confirmation"
                visible={showDeleteDialog}
                style={{ width: "370px" }}
                modal
                footer={
                    <div>
                        <Button
                            label="No"
                            icon="pi pi-times"
                            onClick={() => setShowDeleteDialog(false)}
                            className="p-button-text"
                        />
                        <Button
                            label="Yes"
                            icon="pi pi-check"
                            onClick={() => deleteComment(deleteId)}
                            autoFocus
                        />
                    </div>
                }
                onHide={() => setShowDeleteDialog(false)}
            >
                <p className="px-2">
                    Are you sure you want to delete this Reply?
                </p>
            </Dialog>
            {/* set collpse to reply to comment */}
            <div
                className="collapse col-md-11 ml-auto mt-3 "
                id={`collapseReply${item?.id}`}
            >
                <ReplyForm
                    item={item}
                    user={user}
                    parent_id={parent_id}
                    body={item?.user?.username}
                />
            </div>
        </div>
    );
}

export default ReplyCard;
