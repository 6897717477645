import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllBantsReq, getMyBantsReq, getSingleBantsReq } from "../../utils/request";

export const getBants = createAsyncThunk(
    'banter/getBants',
    async (payload) => {
        const res = await getAllBantsReq(payload);
        return res.data;
    }
)

export const getMyBants = createAsyncThunk(
    'banter/getMyBants',
    async (payload) => {
        const res = await getMyBantsReq(payload);
        return res.data;
    }
)
export const getSingleBants = createAsyncThunk(
    'banter/getSingleBants',
    async (payload) => {
        const res = await getSingleBantsReq(payload);
        return res.data;
    }
)

export const banterSlice = createSlice(
    {
        name: "banter",
        initialState: {},
        bants: {},
        singleBants: {},
        myBants: {},
        filterOptions: "",
        reducers: {
            filterBanter: (state, { payload }) => {
                state.filterOptions = payload;

            },
        },
        extraReducers: (builder) => {
            builder.addCase(getBants.fulfilled, (state, action) => {
                state.bants = action.payload;
            })
            builder.addCase(getMyBants.fulfilled, (state, action) => {
                state.myBants = action.payload;
            })
            builder.addCase(getSingleBants.fulfilled, (state, action) => {
                state.singleBants = action.payload;
            })
        },
    });


export const { filterBanter } = banterSlice.actions;

export default banterSlice.reducer;
