import moment from "moment";
import React, { useState } from "react";
import { addBanterLikesReq } from "../../../utils/request";
import {  useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import ImagePostCards from "../../Community/components/Post/ImagePostCards";
// import { Tooltip } from 'primereact/tooltip';

// import { toast } from 'react-toastify';
import BodyPost from "../../Community/components/Post/BodyPost";
import VideoPlayer from "../../Community/components/Post/VideoPlayer";
import CommentContainer from "./Comments/CommentContainer";

function SingleBantsCard({ item, isSelected }) {
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth?.user);
    const [isload, setLoading] = useState(false);

    const likeFunc = async (id) => {
        setLoading(true);
        const res = await addBanterLikesReq({ bant_id: id }, id);
        // console.debug(res)
        if (res && res.data) {
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    return (
        <div
            className={`post-cards shadow-sm border ${
                item?.type === "leaderboard" && "leaderBoardDesign"
            }`}
            id={`post${item?.id}`}
        >
            <div className="row m-0">
                <div className="col-12 p-0">
                    <p className="topinfo pr-0">
                        <div className="users">
                            <img
                                src={
                                    item?.user?.profile?.profile_photo ??
                                    `/images/emeka.png`
                                }
                                className={"link profile-avatar"}
                                onClick={() =>
                                    navigate(
                                        `/profile?user=${item?.user?.email}`
                                    )
                                }
                                alt=""
                            />
                            <span
                                onClick={() =>
                                    navigate(
                                        `/profile?user=${item?.user?.email}`
                                    )
                                }
                                className="name link ml-2"
                            >
                                {item?.user?.profile?.first_name
                                    ? item?.user?.profile?.first_name +
                                      " " +
                                      item.user?.profile?.last_name
                                    : item?.user?.username}
                            </span>
                            <small className="time">
                                {moment(item?.created_at).fromNow()}
                            </small>
                        </div>
                    </p>
                    <p className="post_text">
                        <BodyPost body={item?.description} />

                        {/* {item?.description} */}
                        {/* {item?.leaderboard_challenges?.leaderboard
                            ?.hash_tag && (
                            <small className="d-block mt-2 font-weight-bolder text-italic">
                                #
                                {
                                    item?.leaderboard_challenges?.leaderboard
                                        ?.hash_tag
                                }{" "}
                            </small>
                        )} */}
                    </p>

                    <div className="text-center">
                        {item?.images.length ? (
                            <ImagePostCards
                                image={item?.images}
                                height={"400px"}
                            />
                        ) : (
                            ""
                        )}
                    </div>

                    {item?.type === "leaderboard" &&
                        item?.leaderboard_challenges?.video && (
                            <VideoPlayer
                                video={item?.leaderboard_challenges?.video}
                            />
                        )}

                    <div className="footer-box">
                        <span className="tag">
                            <a
                                data-toggle="collapse"
                                href={`#collapse${item?.id}`}
                                aria-expanded="false"
                                aria-controls="contentId"
                            >
                                <img
                                    src="/images/box/comment.png"
                                    title="Comments"
                                    alt=""
                                />
                            </a>
                            {item?.comments_count > 0 && (
                                <span> {item?.comments_count}</span>
                            )}
                        </span>
                        <span className="tag">
                            {item?.likes?.find(
                                (isLike) => isLike?.user_id === user?.id
                            ) || isload ? (
                                <img
                                    onClick={() => likeFunc(item?.id)}
                                    src="/images/box/lovep.png"
                                    title="Unlike Post"
                                    alt=""
                                />
                            ) : (
                                <img
                                    onClick={() => likeFunc(item?.id)}
                                    src="/images/box/love.png"
                                    title="Like Post"
                                    alt=""
                                />
                            )}
                            {item?.likes_count > 0 && (
                                <span> {item?.likes_count}</span>
                            )}
                        </span>
                    </div>
                    {/* collpase comment */}
                    <div
                        className="collapse"
                        id={`collapse${item?.id}`}
                        key={item?.id}
                        style={{ height: "90px" }}
                    >
                        <CommentContainer item={item} user={user} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleBantsCard;
