import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { searchBlogFun } from "../../../../store/Blog";

function InputMobile() {
    const location = useLocation();
    const dispatch = useDispatch();

    // Extract the 'keyword' from the URL
    const params = new URLSearchParams(location.search);
    const keyword = params.get("keyword") || ""; // Default to empty string if no keyword
    console.debug(keyword);
    // Local state for the input value, initially set to the URL keyword
    const [inputValue, setInputValue] = useState(keyword);

    // Load search term into Redux when input changes
    const loadSearch = (e) => {
        const value = e.target.value;
        setInputValue(value);
        dispatch(searchBlogFun(value));
    };

    // Sync URL keyword with Redux state on component mount
    useEffect(() => {
        if (keyword) {
            dispatch(searchBlogFun(keyword));
        }
    }, [keyword, dispatch]);

    // Check if we are on excluded routes
    const isCurrent = location.pathname;
    if (
        isCurrent !== "/contact" &&
        isCurrent !== "/terms" &&
        isCurrent !== "/policy"
    ) {
        return (
            <section id="heroInputMobile" className="d-md-none d-block">
                <img src="/images/search.png" alt="" />
                <input
                    type="text"
                    placeholder="Search Topics and Tags"
                    onChange={loadSearch}
                    value={inputValue} // Controlled input bound to local state
                />
            </section>
        );
    }
    return null; // Render nothing on excluded routes
}

export default InputMobile;
