import React from "react";
import EntriesHeader from "./EntriesHeader";
import RankStories from "./RankStories";
import SideTextLeaderBoard from "./SideTextLeaderBoard";
import RankTable from "./RankTable";

function Entry({ leaderboard_challange, current_leaderBoard }) {

    return (
        <div className="Entry">
            <div className="container-fluid">
                <EntriesHeader />
                <RankStories leaderboard_challange={leaderboard_challange} />
                <hr className="my-5" />
                <div className="tableBox">
                    <div className="row">
                        <div className="col-md-4">
                            <SideTextLeaderBoard
                                current_leaderBoard={current_leaderBoard}
                            />
                        </div>
                        <div className="col-md-8 p-0">
                            <RankTable />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Entry;
