import React, { useRef, useState } from "react";
// import { Carousel } from "primereact/carousel";
import { Image } from "primereact/image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Galleria } from "primereact/galleria";

export default function ImagePostCards({ image, height, isLarge }) {
    const icon = (
        <div
            className="overlay"
            onClick={() => {
                galleria.current.show();
                setIndicateHover(false);
            }}
            onMouseLeave={() => setIndicateHover(false)}
        >
            <i className="pi pi-eye text-white"></i>
        </div>
    );
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000, // Adjust the autoplay speed as needed
    };

    const galleria = useRef(null);
    const [indicateHover, setIndicateHover] = useState(false);
    const responsiveOptions = [
        {
            breakpoint: "1500px",
            numVisible: 5,
        },
        {
            breakpoint: "1024px",
            numVisible: 3,
        },
        {
            breakpoint: "768px",
            numVisible: 2,
        },
        {
            breakpoint: "560px",
            numVisible: 1,
        },
    ];

    const itemTemplate = (item) => {
        return (
            <img
                src={item.url}
                alt={item.alt}
                style={{ width: "100%", display: "block" }}
            />
        );
    };

    const thumbnailTemplate = (item) => {
        return (
            <img src={item.url} alt={item.alt} style={{ display: "block" }} />
        );
    };

    return (
        <>
            <Galleria
                ref={galleria}
                value={image}
                responsiveOptions={responsiveOptions}
                numVisible={9}
                style={{ maxWidth: "50%" }}
                circular
                fullScreen
                showItemNavigators
                item={itemTemplate}
                thumbnail={thumbnailTemplate}
                showThumbnails={false}
            />

            <Slider {...settings}>
                {image &&
                    image?.map((item, index) => (
                        <div
                            key={index}
                            className={isLarge?  'picture-slide-large':'picture-slide'}
                            style={{ height }}
                        >
                            <Image
                                src={item.url}
                                // zoomSrc={item.url}
                                alt={item.old_name}
                                width="100%"
                                height="100%"
                                // preview
                                indicatorIcon={icon}
                                onMouseOver={() => setIndicateHover(true)}
                                className="indicateHover "
                            />
                            {indicateHover ? icon : ""}
                        </div>
                    ))}
            </Slider>
        </>
    );
}
