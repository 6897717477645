import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { searchBlogFun } from "../../../../store/Blog";

function HeroInput() {
    const location = useLocation();
    const isCurrent = location.pathname;
    const dispatch = useDispatch();
    const loadSearch = (e) => dispatch(searchBlogFun(e));

    if (
        isCurrent !== "/contact" &&
        isCurrent !== "/terms" &&
        isCurrent !== "/policy"
    )
        return (
            <section id="heroInput" className="d-md-block d-none">
                <img src="/images/search.png" alt="" />
                <input
                    type="text"
                    placeholder="Search Topics and Tags"
                    onChange={(e) => loadSearch(e.target.value)}
                />
            </section>
        );
}

export default HeroInput;
