import React, { useState } from "react";
import { toast } from "react-toastify";
import { createPostReq } from "../../../utils/request";
// import { gePost } from "../../../store/Community";
// import { useDispatch } from "react-redux";
import { Editor } from "primereact/editor";

function CreateLeaderboard({ auth, checkPaticipant, changeVisibility }) {
    // const dispatch = useDispatch();
    const [value, setformValue] = useState();
    const [isload, setLoading] = useState(false);
    const comment_input = document.querySelector("#postMessage");

    // const [img, setImg] = useState([]);
    const [video, setVideo] = useState(null);
    const maxSize = 50 * 1024 * 1024; // 50 MB in bytes

    const handleChange = (e) => {
        const file = e.target.files[0];

        if (file.size > maxSize) {
            return toast.warning(
                "File size exceeds 50 MB. Please select a smaller file."
            );
        }

        const url = URL.createObjectURL(file);
        setVideo({ url, video: file });
    };

    const formData = new FormData();
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        //close modal toggle
        changeVisibility(false);
        toast.success("Uploading Post...");

        formData.append("video", video?.video);
        formData.append(
            "body",
            `${value} \n <br/> <b>#${checkPaticipant?.leaderBoard?.hash_tag}</b>` ??
                " "
        );

        formData.append("type", "leaderboard");
        formData.append("leaderboard_id", checkPaticipant?.leaderBoardId);

        const res = await createPostReq(formData);
        //  window.location.reload();
        // toast.update("toastId.current", {
        //     render: "Your message...",
        //     type: "success",
        //     isLoading: "false",
        // });
        if (res && res.data) {
            comment_input.value = "";
            setLoading(false);
            // ModalClose("loadPopup")
            toast.success("Post Uploaded successfully!");
        } else if (res && res?.response) {
            toast.error(res?.response?.data?.message);
            setLoading(false);
        } else {
            toast.info("Oops something went wrong!");
            setLoading(false);
        }
    };

    return (
        <form className="postBox" onSubmit={(e) => handleSubmit(e)}>
            <div className="row m-0">
                <div className="col-2 text-center p-0 d-none d-md-block">
                    <img
                        src={auth?.profile?.profile_photo}
                        alt=""
                        className="profile-large-avatar"
                    />
                </div>
                <div className="col-md-9 p-md-0">
                    <div className="editorBox">
                        <Editor
                            focus
                            value={value}
                            onTextChange={(e) => setformValue(e.htmlValue)}
                            className="text_Editor shadow-sm"
                        />
                    </div>

                    <div>
                        <div>
                            {video && (
                                <video
                                    width="200"
                                    height={"140"}
                                    controls
                                    src={video?.url}
                                    className="shadow border-rounded"
                                />
                            )}
                        </div>
                    </div>
                    {!video &&
                        checkPaticipant?.alreadyParticipated !== true && (
                            <div
                                class="alert alert-primary alert-dismissible fade show"
                                role="alert"
                            >
                                You are about to join the LeaderBoard challenge{" "}
                                <strong className="hastag">
                                    {checkPaticipant?.leaderBoard?.hash_tag
                                        ? `#${checkPaticipant?.leaderBoard?.hash_tag}`
                                        : ""}
                                </strong>{" "}
                                You have just one entry, and submission cannot
                                be reversed once posted.
                            </div>
                        )}
                    <div className="postBottom">
                        {checkPaticipant?.alreadyParticipated ? (
                            <p className="d-block text-center m-auto">
                                <div class="alert alert-primary" role="alert">
                                    <strong>Note:</strong>{" "}
                                    {checkPaticipant?.message}
                                </div>
                            </p>
                        ) : (
                            checkPaticipant?.alreadyParticipated !== true && (
                                <div className="f-img">
                                    <div className="file-input">
                                        <input
                                            type="file"
                                            name="video"
                                            id="video"
                                            className="file-input__input"
                                            accept="video/*"
                                            onChange={handleChange}
                                        />

                                        <label
                                            className="file-input__label"
                                            for="video"
                                        >
                                            <span>
                                                Upload file
                                                <span className="ml-3 mr-0">
                                                    <img
                                                        src="/images/uploadVideo.png"
                                                        alt=""
                                                    />
                                                </span>
                                            </span>
                                            <small>50mb Max</small>
                                        </label>
                                    </div>
                                </div>
                            )
                        )}
                        {/* {error && <small style={{ color: 'red' }}>{error}</small>} */}

                        <div className="s-img">
                            {video && value && (
                                <button
                                    className="btn add-post "
                                    disabled={
                                        isload === true ||
                                        checkPaticipant?.alreadyParticipated
                                            ? true
                                            : false
                                    }
                                >
                                    {isload ? "Posting..." : "Post"}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default CreateLeaderboard;
