import React from 'react'
import BlogCards from '../../components/BlogCards'

function TrendingNews() {
    return (
        <div className='trendingNews'>
            <h1 >More Trending News</h1>

            {/* <div className="container-fluid"> */}
                <BlogCards length={3} />

            {/* </div> */}
        </div>
    )
}

export default TrendingNews
