


export const copyClip = async (clipText) => {
    try {
        return await navigator.clipboard.writeText(clipText);
        /* Resolved - text copied to clipboard successfully */
    } catch (err) {
        return err
        /* Rejected - text failed to copy to the clipboard */
    }
}
