import moment from 'moment';
import React from 'react'
// import { fixturesArray } from '../../../utils/landingPage'
import { useSelector } from 'react-redux'



const FixturesComponent = ({ item }) => {
    return (

            <div className="col-lg-3 col-md-4  block p-0 border m-2 m-auto rounded-lg shadow-sm ">
                <div className="home  text-right col-5">
                    <span className="pr-1">
                        {item?.teams?.home?.name.slice(0, 5)}..
                    </span>
                    <img src={item?.teams?.home?.logo} alt="" />
                </div>
                <div className="vs col-2 text-center">
                    {item?.fixture?.status?.elapsed ? (
                        <span>
                            {"‘"}
                            {item?.fixture?.status.short === "HT"
                                ? "HT"
                                : item?.fixture?.status?.elapsed > 90
                                ? "FT"
                                : item?.fixture?.status?.elapsed}
                        </span>
                    ) : (
                        <span>
                            {moment(item?.fixture?.date).format("HH:MM")}{" "}
                        </span>
                    )}
                </div>
                <div className="away text-left col-5">
                    <img src={item?.teams?.away?.logo} alt="" />
                    <span className="pl-1">
                        {item?.teams?.away?.name.slice(0, 7)}...
                    </span>
                </div>
            </div>


    );
}


function TodayFixture() {
    const data = useSelector((state) => state.livescores.today)


    return (
        <div className="TodayFixture">
            <div className="plank-board-for-fixtures">Today’s fixture</div>
            <div className="postNews community_fixtures todaysFixtureCommunity ">
                      <div className="row m-0 p-3">
                {data?.slice(0, 16)?.map((item, key) => (
                    <FixturesComponent item={item} key={key} />
                ))}
            </div>
             </div>
        </div>
    );
}

export default TodayFixture
