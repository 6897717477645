import React, { useEffect } from "react";
import NotificationsCards from "./components/NotificationsCards";
import { useDispatch, useSelector } from "react-redux";
import { getNotify } from "../../store/Notification";
import { readNotification } from "../../utils/request";

function Notification() {
    const dispatch = useDispatch();
    //  Todo: Test Notification
    const notification = useSelector(
        (state) => state.notification?.notification
    );
    const notificationCount = useSelector(
        (state) => state.notification?.notificationCount
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadNotification = async () => {
        if (notificationCount) {
            await readNotification();
        }
    };

    useEffect(() => {
        loadNotification();
        dispatch(getNotify());

        const intervalId = setInterval(() => {
            dispatch(getNotify());
            loadNotification();
        }, 7000); // Run every 5 seconds (5000 milliseconds)

        // Clean up function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [dispatch, loadNotification]);

    return (
        <div className="notification p-4">
            <h5>Notification</h5>
            {notification &&
                notification?.map((item, key) => (
                    <NotificationsCards item={item} key={key} />
                ))}
        </div>
    );
}

export default Notification;
