import React from 'react'
import HeroInput from '../HeroInput/index';

function Hero() {


  return (

   <section  id="hero"className='container-fluid '>
          <h1>The World of Football, United as One</h1>
          <HeroInput />
    </section>
  )
}

export default Hero
