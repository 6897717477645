import React, { useState } from "react";
import { twoFactorReq } from "../../../utils/request"; // Assuming you have an API request for updating user details
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";

export default function PrivacySafety() {
    // State to hold form data
    const auth = useSelector((state) => state.auth?.user);
    // const [loading, setLoading] = useState(false);
    const [checkedPost, setCheckedPost] = useState(false);
    const [checkedMessage, setCheckedMessage] = useState(false);

    const updateUserDetails = async (e) => {
        e.preventDefault();
        // setLoading(true); // Show loader

        // Prepare data to send to the API
        try {
            // Send request to the API
            const response = await twoFactorReq();
            // Handle successful details update (e.g., show success message)
            if (response?.data) {
                toast.success(
                    `2Factor Authentication is ${
                        auth?.is_2fa_enabled === 0 ? "Enabled" : "Disabled"
                    } successfully!`
                );
            } else {
                toast.error(
                    response?.response?.data?.message ??
                        `Failed to ${
                            auth?.is_2fa_enabled === 0 ? "Enabled" : "Disabled"
                        }. Please try again.`
                );
            }
        } catch (err) {
            // Handle error (e.g., show error message)
            toast.error(
                `Failed to ${
                    auth?.is_2fa_enabled === 0 ? "Enabled" : "Disabled"
                }. Please try again.`
            );
        } finally {
            // setLoading(false); // Hide loader after request completion
        }
    };

    return (
        <form onSubmit={updateUserDetails}>
            <div className="row col-md-6 col-xl-4 py-4">
                <div className="col-12 py-2">
                    <h5>
                        <b>
                            <b> Your Post</b>
                        </b>
                    </h5>
                </div>

                <div className="col-12 mb-3">
                    <p>
                        <b>
                            Mark media you post as having material that may be
                            sensitive.
                        </b>
                        <br />
                        When enabled, pictures and videos you post will be
                        marked as sensitive for people who don’t want to see
                        sensitive content.
                        <Link className="text-primary" to="/terms">
                            Learn more
                        </Link>
                    </p>
                    <div className=" d-flex  align-items-center">
                        {/* <div>Off</div> */}
                        <div className="px-0">
                            <InputSwitch
                                checked={checkedPost}
                                onChange={(e) => setCheckedPost(e.value)}
                            />
                        </div>
                        {/* <div>On</div> */}
                    </div>
                </div>
            </div>
            <hr />

            <div className="row col-md-6 col-xl-4 py-4">
                <div className="col-12 py-2">
                    <h5>
                        <b>
                            <b> Direct message</b>
                        </b>
                    </h5>
                </div>

                <div className="col-12 mb-3">
                    <p>
                        <b>Read receipt</b> <br />
                        Let people you’re messaging with know when you’ve seen
                        their messages. Read receipts are not shown on message
                        requests.
                        <Link className="text-primary" to="/terms">
                            Learn more
                        </Link>
                    </p>
                    <div className=" d-flex  align-items-center">
                        {/* <div>Off</div> */}
                        <div className="px-0">
                            <InputSwitch
                                checked={checkedMessage}
                                onChange={(e) => setCheckedMessage(e.value)}
                            />
                        </div>
                        {/* <div>On</div> */}
                    </div>
                </div>
            </div>
            <hr />
        </form>
    );
}
