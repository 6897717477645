import React from 'react'
import { useNavigate } from 'react-router-dom';

function HeaderMessage({ profile }) {
    const navigate=useNavigate()
    const bio = profile?.profile?.bio ?? '';
    return (
        <div className='chat-head' >
            <div className='back d-md-none' onClick={() => navigate(-1)} >
                <img src="/images/back.png"  alt="" />
            </div>

            <div className='imgB' onClick={() => navigate(`/profile?user=${profile?.email}`)}>  <img src={profile?.profile?.profile_photo ?? "/images/chat-profile.png"} alt="" className='imgAvatarsBoard shadow-sm' /></div>
            <div className='user' onClick={() => navigate(`/profile?user=${profile?.email}`)}>   <h2  >
                <span> {profile?.profile?.first_name}  {profile?.profile?.last_name}</span>
            </h2>
                <p> {(bio?.length <= 50) ? bio : bio?.slice(0, 50) + " ..."} </p>
            </div>

        </div>
    )
}

export default HeaderMessage
