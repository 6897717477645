import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getArticlesReq, getBlogReq, getSingleBlogReq } from "../../utils/request";

export const getBlog = createAsyncThunk(
    'user/getBlog',
    async () => {
        const res = await getBlogReq()
        return res.data.categories;
    }
)
export const getSingleBlog = createAsyncThunk(
    'user/getSingleBlog',
    async (queryId) => {
        const res = await getSingleBlogReq(queryId);
        return res.data?.article;
    }
)
export const getArticles = createAsyncThunk(
    'user/getArticles',
    async () => {
        const res = await getArticlesReq();
        return res.data;
    }
)

export const blog = createSlice(
    {
        name: "blog",
        initialState: {
        },
        categories: [],
        articles: {},
        allArticles: "",
        searchBlog: null,

        reducers: {
            openIschat: (state, action) => {
                //
            },
            searchBlogFun: (state, action) => {
                state.searchBlog = action.payload
            },
        },

        extraReducers: (builder) => {
            builder.addCase(getBlog.fulfilled, (state, action) => {
                state.categories = action.payload
            })
            builder.addCase(getSingleBlog.fulfilled, (state, action) => {
                state.articles = action.payload
            })
            builder.addCase(getArticles.fulfilled, (state, action) => {
                state.allArticles = action.payload
            })

        }

    });


export const { openIschat, searchBlogFun } = blog.actions;

export default blog.reducer;
