import { TabView, TabPanel } from "primereact/tabview";

import React, { useEffect, useState } from "react";
import Profile from "./components/Profile";
import SuggestedFreinds from "./components/SuggestedFreinds";
import FixturesSub from "./components/Fixtures";
import TrendingNews from "./components/TrendForYou/TrendingNews";
import TrendForYou from "./components/TrendForYou/TrendForYou";
import Postcards from "./components/Post/Postcards";
import { useDispatch, useSelector } from "react-redux";
import { gePost } from "../../store/Community";
import PostLoader from "../../Loaders/PostLoader";
// import { useLocation } from "react-router-dom";
import SearchPost from "./components/Post/PostBlog";
import RankStories from "../LeaderBoard/components/RankStories";

function SearchPage() {
    const posts = useSelector((state) => state.community?.posts);
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth?.user);

    // console.debug(typeof(posts))

    useEffect(() => {
        // Dispatch gePost initially
        dispatch(gePost());

        // Set interval to dispatch gePost every 5 seconds
        const intervalId = setInterval(() => {
            dispatch(gePost());
        }, 5000); // 5000 milliseconds = 5 seconds

        // Cleanup function to clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [dispatch]); // Dependency array with dispatch as dependency
    const [noTrends, setNoTrends] = useState(false);

    // Callback to update the `noTrends` state
    const handleNoTrends = (isNoTrends) => {
        setNoTrends(isNoTrends);
    };
    const { leaderboard_challange } = useSelector((state) => state.leaderboard);
    return (
        <div className=" community searchedPage">
            <div className="container-fluid ">
                <div className="row m-0">
                    <div className="col-md-3  sidebarleft p-0 ml-auto centerPage d-none d-md-block">
                        <Profile user={auth} />
                        <TrendingNews user={auth} />
                        <SuggestedFreinds auth={auth} length={3} />
                    </div>
                    <div className="col-md-6 p-0 mx-auto centerPage ">
                        <SearchPost />
                        {/* {type != "trend" ? (
                            <div className="mx-md-4" history>
                                <PostBlog />
                                {posts?.length ? (
                                    posts?.map((item, key) => (
                                        <Postcards item={item} key={key} />
                                    ))
                                ) : (
                                    <PostLoader />
                                )}
                            </div>
                        ) : (
                            <div className="mx-md-4">
                                <TrendForYou user={auth} />
                            </div>
                        )} */}

                        <div className=" rounded-lg   p-0 mx-4">
                            <TabView>
                                <TabPanel header="From You ">
                                    <div className="bg-white rounded-lg mt-1 ">
                                        <span>Videos from Leaderboard</span>
                                        <RankStories
                                            leaderboard_challange={
                                                leaderboard_challange
                                            }
                                        />
                                    </div>

                                    {/* video */}
                                    {noTrends && (
                                        <div className="mt-2">
                                            <TrendForYou
                                                user={auth}
                                                trendsSearch={true}
                                                noTrends={handleNoTrends} // pass the callback
                                            />
                                        </div>
                                    )}
                                    {posts?.length ? (
                                        posts?.map((item, key) => (
                                            <Postcards item={item} key={key} />
                                        ))
                                    ) : (
                                        <PostLoader />
                                    )}
                                </TabPanel>
                                <TabPanel header="Trending">
                                    <div className="mt-2">
                                        <TrendForYou
                                            user={auth}
                                            trendsSearch={true}
                                        />
                                    </div>
                                </TabPanel>
                                <TabPanel header="News">
                                    <TrendingNews length={20} />
                                </TabPanel>
                                <TabPanel header="Sport">
                                    <FixturesSub user={auth} length={20} />
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                    <div className="col-md-3  p-0 mr-auto centerPage d-none d-md-block">
                        <TrendForYou user={auth} length={8} />
                        <FixturesSub user={auth} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchPage;
