import React, { useState } from "react";
import {  useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { qParams } from "../../../../utils/getQueryParams";
// import { gePost } from "../../../../store/Community";

// const post = [1, 2, 3, 4, 5, 6, 7];
function TrendForYou({ length }) {
    const keywords = useSelector((state) => state.community?.keywords);
    // const navigate = useNavigate();
    // const location = useLocation();
    // const dispatch = useDispatch();
    // const [history, setHistory] = useState(window.location);
    const [search, setSearch] = useState("");
    const filtered = () => {
        return keywords?.trends?.filter((item) =>
            item.keyword.toLowerCase().includes(search.toLowerCase())
        );
    };

    // const q = qParams("q", location.search);
    // useEffect(() => {
    //     setHistory(window.location);
    //     dispatch(gePost({ q }));
    // }, [location]);


    return (
        <div className="trending_news headbox p-0 m-0 border">
            <div className="header">
                {length ? (
                    <>
                        <span> Trends for you</span>
                        <Link to="/community">
                            <img src="/images/reload.png" alt="" />
                        </Link>
                    </>
                ) : (
                    <>
                        <input
                            type="search"
                            name=""
                            id=""
                            className="form-control  mr-2 p-4 shadow-sm searchInput"
                            placeholder="Search Trends for you "
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <button className="btn btn-blue p-4 d-flex  align-items-center shadow ">
                            <i className="pi pi-search"></i>
                        </button>
                    </>
                )}
            </div>
            <div className="postNews">
                {filtered()?.length ? (
                    filtered()
                        ?.slice(0, length)
                        ?.map((item, key) => (
                            <div
                                className="row m-0 keywordsText "
                                key={key}
                                onClick={() =>
                                    window.location.href=`/community?q=${item?.keyword}`
                                }
                            >
                                <div className="col-8 p-0 ">
                                    <h3 className="link ">{item.keyword}</h3>
                                    <p>{item.keywordCount} Posts</p>
                                </div>

                                <div className="col-12 p-0 m-0">
                                    <hr className="p-0 m-0" />
                                </div>
                            </div>
                        ))
                ) : (
                    <div className="p-5  text-center">No Trends Found!!!</div>
                )}

                {length && (
                    <div className="text-center  p-2">
                        <Link
                            to="/community?type=trend"
                            className="link-btn btn"
                        >
                            See more
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TrendForYou;
