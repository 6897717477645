import CreateBanter from "./CreateBanter";

function BanterForm({ auth }) {
    return (
        <div
            className="modal fade shadow"
            id="loadBanter"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modelTitleId"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-body cratePostTab p-0 pt-3">
                        {/* pull in tab */}
                        <ul
                            className="nav nav-pills   mb-3 border-bottom justify-content-center "
                            id="pills-tab"
                            role="tablist"
                        >
                            <li className="nav-item">
                                <a
                                    className="nav-link font-weight-bold link"
                                    id="pills-home-tab"
                                    data-toggle="pill"
                                    href="#pills-home"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected="true"
                                >
                                    Create a Banter
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                            >
                                <CreateBanter auth={auth} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BanterForm;
