import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Followings({ items, title }) {
    const navigate = useNavigate();
    return (
        <div className="trending_news suggestedfreinds headbox">
            <div className="header">
                <span> {title} </span>
            </div>
            <div className="postNews ">
                {!items.length && (
                    <div className="p-3 text-center">No {title} Found!!</div>
                )}
                {items &&
                    items?.map((item, key) => (
                        <div
                            className="row m-0 p-0 align-items-center"
                            key={key}
                        >
                            <div className=" p-2 pl-4 ">
                                <img
                                    src={item?.profile?.profile_photo}
                                    className="profile-img shadow link"
                                    alt=""
                                    onclick={() =>
                                        navigate(
                                            `/profile?user=${item?.email}`
                                        )
                                    }
                                />
                            </div>
                            <div className="col-8 p-0">
                                <div className="row m-0 p-0">
                                    <div className="col-7 p-0">
                                        <h3>
                                            <Link
                                                to={`/profile?user=${item?.email}`}
                                                className="text-dark"
                                            >
                                                {item?.profile?.first_name ??
                                                    item?.username}
                                                {item?.profile?.last_name}
                                            </Link>
                                        </h3>
                                        <p>@{item?.username}</p>
                                    </div>
                                    <div className="col-5 p-0">
                                        <i
                                            className="link pi pi-user btn"
                                            onClick={() =>
                                                navigate(
                                                    `/profile?user=${item?.email}`
                                                )
                                            }
                                        ></i>
                                        <i
                                            className="link pi pi-comments btn"
                                            onClick={() =>
                                                navigate(
                                                    `/chats?id=${item?.id}&user=${item?.username}`
                                                )
                                            }
                                        ></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 p-0 m-0">
                                <hr className="p-0 m-0" />
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default Followings;
