import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
function FixturesSub({ length = 10 }) {
    const data = useSelector((state) => state.livescores?.today);

    if (data?.length)
        return (
            <div className="trending_news headbox  border">
                <div className="header">
                    <span> Fixtures </span>
                    <a href="/community">
                        <img src="/images/reload.png" alt="" />
                    </a>
                </div>
                <div className="postNews community_fixtures">
                    {data?.slice(0, length)?.map((item, key) => (
                        <div className="row m-0">
                            <div className="col-12 block p-0 ">
                                <div className="home  text-right col-5">
                                    <span className="pr-1">
                                        {item?.teams?.home?.name.slice(0, 7)}...
                                    </span>
                                    <img src={item?.teams?.home?.logo} alt="" />
                                </div>
                                <div className="vs col-2">
                                    {item?.fixture?.status?.elapsed ? (
                                        <span>
                                            {"‘"}
                                            {item?.fixture?.status.short ===
                                            "HT"
                                                ? "HT"
                                                : item?.fixture?.status
                                                      ?.elapsed > 90
                                                ? "FT"
                                                : item?.fixture?.status
                                                      ?.elapsed}
                                        </span>
                                    ) : (
                                        <span>
                                            {moment(item?.fixture?.date).format(
                                                "HH:MM"
                                            )}{" "}
                                        </span>
                                    )}
                                </div>
                                <div className="away text-left col-5">
                                    <img src={item?.teams?.away?.logo} alt="" />
                                    <span className="pl-1">
                                        {item?.teams?.away?.name.slice(0, 7)}...
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 p-0 m-0">
                                <hr className="p-0 m-0" />
                            </div>
                        </div>
                    ))}

                    <div className="text-center p-2 ">
                        <Link to="/sport" className="link-btn btn">
                            See more
                        </Link>
                    </div>
                </div>
            </div>
        );
}

export default FixturesSub;
