import React, { useEffect, useState } from "react";
import { updateUsernameReq } from "../../../utils/request"; // Assuming you have an API request for updating user details
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function ChangeUserDetails() {
    // State to hold form data
    const auth = useSelector((state) => state.auth?.user);
    const [username, setUsername] = useState(auth?.username);
    // const [email, setEmail] = useState(auth?.email);
    const [loading, setLoading] = useState(false); // Loader state
    useEffect(() => {}, []);
    const updateUserDetails = async (e) => {
        e.preventDefault();
        setLoading(true); // Show loader

        // Prepare data to send to the API
        const data = {
            username
        };

        try {
            // Send request to the API
           const response= await updateUsernameReq(data);
            // Handle successful details update (e.g., show success message)

             if (response?.data) {
                 toast.success("Account details updated successfully!");
             } else {
                 toast.error(
                     response?.response?.data?.message ??
                         "Failed to update account details. Please try again."
                 );
             }
        } catch (err) {
            // Handle error (e.g., show error message)
            toast.error("Failed to update account details. Please try again.");
        } finally {
            setLoading(false); // Hide loader after request completion
        }
    };

    return (
        <form onSubmit={updateUserDetails}>
            <div className="row col-md-6 col-xl-4 py-4">
                <div className="col-12 py-2">
                    <h5>Account Information</h5>
                </div>
                {/* Error message */}
                <div className="col-12 mb-3">
                    <label htmlFor="">Username</label>

                    <input
                        type="text"
                        className="form-control py-4"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)} // Update username state
                        placeholder="Enter Username"
                    />
                </div>
               {/* { auth?.username} */}
                {/* <div className="col-12 mb-3">
                    <label htmlFor="">Email</label>
                    <input
                        type="email"
                        className="form-control py-4"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} // Update email state
                        placeholder="Enter Email"
                    />
                </div> */}
                <div className="col-12 mt-2">
                    <button
                        className="btn btn-blue"
                        type="submit"
                        disabled={loading} // Disable button when loading
                    >
                        {loading // Show loader when loading is true
                            ? "Saving . . . . . . . "
                            : "Save Changes"}
                    </button>
                </div>
            </div>
            <hr />
        </form>
    );
}
