import React from "react";
import { Skeleton } from "primereact/skeleton";

export default function PostLoader({ length }) {
    const array = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    const Details = () => (
        <div
            className="card mt-3"
            style={{
                borderRadius: "10px",
            }}
        >
            <div className="border-round border-1 surface-border p-4 surface-card">
                <div className="flex mb-3">
                    <Skeleton
                        shape="circle"
                        size="4rem"
                        className="mr-2"
                    ></Skeleton>
                    <div>
                        <Skeleton width="10rem" className="my-2 "></Skeleton>
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                        <Skeleton height=".5rem"></Skeleton>
                    </div>
                </div>
                <Skeleton width="100%" height="250px"></Skeleton>
                <div className="flex justify-content-between mt-3">
                    <Skeleton width="4rem" height="2rem"></Skeleton>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {array.slice(0, length).map((i, k) => (
                <Details key={k} />
            ))}
        </>
    );
}
