import React from 'react'
import Profile from '../Community/components/Profile'
import { user } from "../../DB"
import SuggestedFreinds from '../Community/components/SuggestedFreinds'
import FixturesSub from '../Community/components/Fixtures'
import TrendingNews from '../Community/components/TrendForYou/TrendingNews'
import TrendForYou from '../Community/components/TrendForYou/TrendForYou'
import {  useSelector } from 'react-redux'
import BanterHeader from './components/BanterHeader'
import LoadBant from './components/LoadBant'
/*eslint-disable*/

function Banter() {
       const auth = useSelector((state) => state.auth?.user);
    // const dispatch = useDispatch()


    return (
        <div className=" community">
            <div className="container-fluid ">
                <div className="row m-0">
                    <div className="col-md-3 sidebarleft p-0 mx-auto centerPage d-none d-md-block">
                        <Profile user={user} />
                        <TrendingNews user={user} />
                        <SuggestedFreinds user={user} />
                    </div>
                    <div className="col-md-6 p-0 mx-auto centerPage">
                        <div className="mx-md-4">
                            <BanterHeader auth={auth} />
                            <LoadBant auth={auth} />
                        </div>
                    </div>
                    <div className="col-md-3 p-0 mx-auto centerPage d-none d-md-block">
                        <TrendForYou user={user} length={8} />
                        <FixturesSub user={user} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banter
