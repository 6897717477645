// import moment from 'moment'
import React, { useRef } from "react";
import { talents } from "../../../utils/landingPage";

function Talents() {
    const scrollContainerRef = useRef(null);

    const handleScroll = (scrollOffset) => {
        if (scrollContainerRef.current) {
            const newScrollLeft =
                scrollContainerRef.current.scrollLeft + scrollOffset;
            scrollContainerRef.current.scrollTo({
                left: newScrollLeft,
                behavior: "smooth",
            });
        }
    };

    return (
        <div className="container-fluid talents">
            <h4 className="text-talent">Talents</h4>
            <div className="talentsBox m-0" ref={scrollContainerRef}>
                {talents?.map((item) => (
                    <iframe
                        src={item.videoUrl}
                        frameBorder="0"
                        allowFullScreen
                        className="talentsCol"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        autoPlay="autoplay"
                        title="Welcome To Soccernity"
                        controls={false}
                        style={{ objectFit: "cover" }}
                    ></iframe>
                ))}
            </div>

            <div className="btn-Group-Box d-none d-md-block">
                <button
                    className="btn btn-blue  shadow  Btn-left"
                    onClick={() => handleScroll(-100)}
                >
                    <i
                        className="pi pi-angle-left
"
                    ></i>
                </button>

                <button
                    className="btn btn-blue shadow  Btn-right"
                    onClick={() => handleScroll(100)}
                >
                    <i
                        className="pi pi-angle-right
"
                    ></i>
                </button>
            </div>
        </div>
    );
}

export default Talents;
