import React, { useEffect } from "react";
import BantCards from "./BantCards";
import { getBants, getMyBants } from "../../../store/Banter";
import { useDispatch, useSelector } from "react-redux";
/*eslint-disable*/

function LoadBant() {
    const name = useSelector((state) => state.banter.filterOptions);
    const payload = `search=${name ?? ""}`;

    const dispatch = useDispatch();

    useEffect(() => {
        // Define a function to fetch data
        const fetchData = () => {
            dispatch(getMyBants(payload));
            dispatch(getBants(payload));
        };

        // Initial fetch
        fetchData();

        // Set up interval to fetch every 5 minutes (300000 ms)
        const intervalId = setInterval(fetchData, 5000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [dispatch]); // Only runs on mount and unmount

    const { bants, myBants } = useSelector((state) => state.banter);
    const filteredBants = () => bants;
    // &&
    // bants?.filter((bant) => {
    //     if (bant?.topic?.toLowerCase()?.includes(name?.toLowerCase()))
    //         return true;
    //     else return true;
    // });
    const filteredMyBants = () => myBants;
    // &&
    // myBants?.filter((myBant) =>
    //     myBant?.topic?.toLowerCase()?.includes(name?.toLowerCase())
    // );

    return (
        <div className="loadBanter">
            <ul className="nav nav-pills pl-2" id="pills-tab" role="tablist">
                <li className="nav-item " role="presentation">
                    <button
                        className="nav-link active btn"
                        id="pills-home-tab"
                        data-toggle="pill"
                        data-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                    >
                        All Bants
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className="nav-link btn"
                        id="pills-profile-tab"
                        data-toggle="pill"
                        data-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                    >
                        My Bants
                    </button>
                </li>
            </ul>
            {/* all bants */}

            <div className="tab-content bg-white shadow" id="pills-tabContent">
                <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                >
                    {filteredBants()?.map((item, key) => (
                        <BantCards item={item} key={key} />
                    ))}
                </div>
                {/* my bants */}
                <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                >
                    {filteredMyBants()?.map((item, key) => (
                        <BantCards item={item} key={key} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default LoadBant;
