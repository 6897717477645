// import $ from "jquery"


export const ModalClose = data => {
    let close = document.querySelectorAll('.modal-backdrop')[0];
    let modal = document.getElementById(data);
    let body = document.getElementsByTagName('body')[0];
    close.style.display = "none"
    body.classList.remove('modal-open')
    modal.classList.remove('show')

}
