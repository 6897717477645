import React from "react";
import { useSelector } from "react-redux";

function RankTable() {
    const topRank = useSelector((state) => state.leaderboard?.topRank);

    return (
        <div className="rankTable col-lg-11 ml-auto ">
            <div className="table-responsive shadow table-Box">
                <table className="table  table-inverse ">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Username</th>
                            <th>Point</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {topRank && topRank?.map((item, key) => (
                            <tr>
                                <td>{key + 1}</td>
                                <td>{item?.user?.username} </td>
                                <td>{item?.votes_count}</td>
                                <td>
                                    {[0, 1, 2].includes(key) && (
                                        <img
                                            src={`/images/mdi_trophy${key}.png`}
                                            alt=""
                                        />
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default RankTable;
