import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addReplyReq } from "../../../../utils/request";
// import { toast } from 'react-toastify';
import { gePost } from "../../../../store/Community";
import { getSavePost } from "../../../../store/Community/index";
function ReplyForm({ user, item, parent_id, comment_id, body }) {
    const dispatch = useDispatch();
    const [value, setValue] = useState({
        body: `${body}   `,
        parent_id,
        comment_id: comment_id ?? item?.id,
    });
    const [isload, setLoading] = useState(false);
    /*eslint-disable*/
    const handleSubmit = async (e) => {
        if (e !== "btn") e.preventDefault();
        setLoading(true);
        const res = await addReplyReq(value);
        if (res && res.data) {
            setLoading(false);
            dispatch(gePost());
            dispatch(getSavePost());
            setValue({ ...value, body: "" });
        } else if (res && res?.response) {
            setLoading(false);
            setValue({ ...value, body: "" });
        } else {
            setLoading(false);
            setValue({ ...value, body: "" });
        }
    };
    return (
        <form
            className="inputBoxComment row m-0 p-0  my-3 "
            onSubmit={(e) => handleSubmit(e)}
        >
            <div className="d-flex align-items-center">
                <img
                    src={
                        item.user?.profile?.profile_photo ?? `/images/emeka.png`
                    }
                    alt=""
                    className="profile-avatar"
                />
            </div>
            {/* <div className='d-flex w-75'> */}
            <textarea
                value={value?.body}
                cols={1}
                rows={1}
                placeholder={`Reply ${body}...`}
                className="comment-input "
                onChange={(e) => setValue({ ...value, body: e.target.value })}
            ></textarea>

            {/* </div> */}
            <div className="sendMessageBox align-items-center ">
                {isload ? (
                    <i
                        className="fa fa-spinner fa-pulse fa-fw ml-3 "
                        aria-hidden="true"
                    ></i>
                ) : (
                    value.body !== "" && (
                        <i
                            className="fa fa-paper-plane-o  "
                            aria-hidden="true"
                            onClick={() => handleSubmit("btn")}
                        ></i>
                    )
                )}
            </div>
        </form>
    );
}

export default ReplyForm;
