import { useDispatch, useSelector } from "react-redux";
import { setSearchAction } from "../../../../store/General";

const PostForm = () => {
    const dispatch = useDispatch();
    const searchAll = useSelector((state) => state.general.searchAll);

    const handleInputChange = (e) => {
        dispatch(setSearchAction(e.target.value));
    };

    return (
        <>
            <div className="col-10 col-md-9 p-md-0">
                <input
                    name="search"
                    placeholder="What’s happening?"
                    value={searchAll} // Set the input value from Redux state
                    onChange={handleInputChange} // Update Redux state on change
                    className="cursorType"
                />
          
            </div>
        </>
    );
};

export default PostForm;
