import { decryptToken } from "./encryption";

export const config = {
    BaseApi: process.env.REACT_APP_RAPID_API,
    RapidAPIKey: process.env.REACT_APP_RAPID_API_KEY,
    RapidAPIHost: process.env.REACT_APP_RAPID_API_HOST,
    Api: process.env.REACT_APP_API,
    Header: {
        headers: {
            "Authorization": `bearer ${decryptToken()}`
        }
    },
    HeaderMultipart: {
        headers: {
            "Authorization": `bearer ${decryptToken()}`,
            "Content-Type": "multipart/form-data",
        }
    },
 
}
