import React, { useState } from 'react'
import { postChatReq } from '../../../utils/request';
import { useDispatch } from 'react-redux';
import { getChat } from '../../../store/Chat/index';

function ChatForm({ profile }) {
    const [value, setformValue] = useState();
    const dispatch = useDispatch();

    const [isload, setLoading] = useState(false);
    const addValue = (e) => {
        setformValue({ ...value, [e.target.name]: e.target.value, receiver_id: profile?.id })
    }
    // const chatContainer = document.getElementById("chatContainer");
    const handleSubmit = async () => {
        setLoading(true)
        const res = await postChatReq(value);
        if (res && res.data) {
            setLoading(false)
            setformValue({ ...value, body: "" })
            dispatch(getChat(profile?.id))
        }
        else if (res && res?.response) {
            setLoading(false)
        }
        else {
            setLoading(false)
        }

    }
    const handleKeyDown=(event)=>{
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit()
        }
    }
    const handleKeyPress=(event)=>{
        if (event.key === 'Enter' && event.shiftKey) {
            setformValue({ ...value, body: value?.body })
        }
    }

    return (
        <div className='form-chat'>
            <div className='inputChat'>   <textarea type="text" className='input-chat form-control' placeholder={`chat with ${profile?.username}`} onChange={(e) => addValue(e)} name="body" value={value?.body}
                onKeyDown={handleKeyDown}
                onKeyPress={handleKeyPress}
            ></textarea></div>
            <div className="sendBox ">
                {isload ? <i className="fa fa-spinner fa-pulse fa-fw ml-2  " aria-hidden="true" ></i> :
                    <i onClick={handleSubmit} type="button" className="fa fa-paper-plane-o ml-2 link " aria-hidden="true"
                    ></i>}
            </div>
        </div>
    )
}

export default ChatForm
