import React, { useState } from "react";
import { twoFactorReq } from "../../../utils/request"; // Assuming you have an API request for updating user details
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import { RadioButton } from "primereact/radiobutton";

export default function Preference() {
    // State to hold form data
    const auth = useSelector((state) => state.auth?.user);
    // const [loading, setLoading] = useState(false);
    // const [checkedPost, setCheckedPost] = useState(false);
    // const [checkedMessage, setCheckedMessage] = useState(false);

    const updateUserDetails = async (e) => {
        e.preventDefault();
        // setLoading(true); // Show loader

        // Prepare data to send to the API
        try {
            // Send request to the API
            const response = await twoFactorReq();
            // Handle successful details update (e.g., show success message)
            if (response?.data) {
                toast.success(
                    `2Factor Authentication is ${
                        auth?.is_2fa_enabled === 0 ? "Enabled" : "Disabled"
                    } successfully!`
                );
            } else {
                toast.error(
                    response?.response?.data?.message ??
                        `Failed to ${
                            auth?.is_2fa_enabled === 0 ? "Enabled" : "Disabled"
                        }. Please try again.`
                );
            }
        } catch (err) {
            // Handle error (e.g., show error message)
            toast.error(
                `Failed to ${
                    auth?.is_2fa_enabled === 0 ? "Enabled" : "Disabled"
                }. Please try again.`
            );
        } finally {
            // setLoading(false); // Hide loader after request completion
        }
    };

     const language = [
         { name: "English", key: "E" },
         { name: "French", key: "F" },
         { name: "Spanish", key: "S" },
         { name: "German", key: "G" },
     ];
     const [selectedLanguage, setSelectedLanguage] = useState(language[1]);

    return (
        <form onSubmit={updateUserDetails}>
            <div className="row col-md-6 col-xl-4 py-4">
                <div className="col-12 py-2">
                    <h5>
                        <b>
                            <b>Language</b>
                        </b>
                    </h5>
                </div>

                <div className="col-12 mb-3">
                    <p>
                        Select your preference by notification type
                        <br />
                    </p>
                    <div className="flex justify-content-center">
                        <div className="d-flex flex-column gap-3">
                            {language.map((language) => {
                                return (
                                    <div
                                        key={language.key}
                                        className="d-flex align-items-center mb-3"
                                    >
                                        <RadioButton
                                            inputId={language.key}
                                            name="category"
                                            value={language}
                                            onChange={(e) =>
                                                setSelectedLanguage(e.value)
                                            }
                                            checked={
                                                selectedLanguage.key ===
                                                language.key
                                            }
                                        />
                                        <label
                                            htmlFor={language.key}
                                            className="ml-2 m-0"
                                        >
                                            {language.name}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </form>
    );
}
