import React from 'react'
import { useNavigate } from 'react-router-dom'

function FeelPassion() {
    const navigate=useNavigate()
    return (
        <div className="FeelPassion container">
            <div className="row">
                <div className="col-md-6  feelPassionText">
                    <h3> 
                        The World of Football,
                        <br /> United as One!
                    </h3>

                    <div className="btnBox-col mt-3">
                        <button
                            className="btn btn-login-info-white"
                            onClick={() => navigate("/auth/signin")}
                        >
                            Login
                        </button>
                        <button
                            className="btn btn-login-info-blue"
                            onClick={() => navigate("/auth/signup")}
                        >
                            Sign up
                        </button>
                    </div>
                </div>
                <div className="col-md-6 ">
                    <img
                        src="/images/twoBallers.png"
                        className="w-100"
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
}

export default FeelPassion
