import React from "react";
import { Link } from "react-router-dom";

function PostMediaBox({ user, auth }) {
    return (
        <div className="PostMediaBox  mx-auto shadow">
            <Link to={`/profile?type=my_post&user=${user}`}>Posts</Link>
            <div className="dividerBox"></div>
            <Link to={`/profile?type=my_media&user=${user}`}>Media</Link>

            {auth?.email === user && (
                <>
                    <div className="dividerBox"></div>
                    <Link to={`/profile?type=my_saved&user=${user}`}>Saved</Link>
                </>
            )}
        </div>
    );
}

export default PostMediaBox;
