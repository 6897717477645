import { useNavigate } from "react-router-dom";

export const useNotificationTypes = () => {
    const navigate = useNavigate();
    const notifyLink = (type, item) => {
        switch (type) {
            case 'LeaderBoard':
                navigate(`/leaderboard`);
                break;
            case 'Post':
                navigate(`/post/${item?.model_id}`);
                break;
            case 'Comment':
                navigate(`/post/${item?.model_id}`);
                break;
            case 'Article':
                navigate(`/blog/${item?.model_id}`);
                break;
            case 'Message':
                const user = encodeURIComponent(item.user.name);
                const id = encodeURIComponent(item.user.id);
                navigate(`/chats?user=${user}&id=${id}`);
                break;
            default:
                console.error("Unknown notification type:", type);
        }
    };

    return notifyLink;
};
