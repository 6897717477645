import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function TrendForYou({ length, trendsSearch, noTrends }) {
    const keywords = useSelector((state) => state.community?.keywords);
    const [search, setSearch] = useState("");

    // Filtered list of trends based on search input
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filtered = useCallback(() => {
        return keywords?.trends?.filter((item) =>
            item.keyword.toLowerCase().includes(search.toLowerCase())
        );
    });

    // Use effect to check if filtered trends are empty and notify parent
    useEffect(() => {
        if (noTrends) {
            noTrends(filtered()?.length === 0);
        }
    }, [search, keywords, noTrends, filtered]);

    return (
        <div
            className={`trending_news headbox p-0 m-0 ${
                trendsSearch ? "" : "border"
            }`}
        >
            {trendsSearch ? (
                ""
            ) : (
                <div className="header">
                    {length ? (
                        <>
                            <span>Trends for you</span>
                            <Link to="/community">
                                <img src="/images/reload.png" alt="" />
                            </Link>
                        </>
                    ) : (
                        <>
                            <input
                                type="search"
                                className="form-control mr-2 p-4 shadow-sm searchInput"
                                placeholder="Search Trends for you"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <button className="btn btn-blue p-4 d-flex align-items-center shadow">
                                <i className="pi pi-search"></i>
                            </button>
                        </>
                    )}
                </div>
            )}
            <div className="postNews">
                {filtered()?.length ? (
                    filtered()
                        .slice(0, length)
                        .map((item, key) => (
                            <div
                                className="row m-0 keywordsText"
                                key={key}
                                onClick={() =>
                                    (window.location.href = `/community?q=${item?.keyword}`)
                                }
                            >
                                <div className="col-8 p-0">
                                    <h3 className="link">{item.keyword}</h3>
                                    <p>{item.keywordCount} Posts</p>
                                </div>
                                <div className="col-12 p-0 m-0">
                                    <hr className="p-0 m-0" />
                                </div>
                            </div>
                        ))
                ) : (
                    <div className="p-5 text-center">No Trends Found!!!</div>
                )}

                {length && (
                    <div className="text-center p-2">
                        <Link
                            to="/community?type=trend"
                            className="link-btn btn"
                        >
                            See more
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TrendForYou;
