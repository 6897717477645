import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    forgotPasswordReq,
    resendTwoFactorAuthentication,
    verifyTwoFactorAuthentication,
} from "../../utils/request";
import { toast } from "react-toastify";
import { qParams } from "../../utils/getQueryParams";
import { encryptToken } from "../../utils/encryption";

function TwoFactorAuth() {
    const [value, setformValue] = useState();
    // const navigate = useNavigate()
    // const [error, setError] = useState();
    const location = useLocation();

    let email = qParams("email", location.search);
    const [isload, setLoading] = useState(false);
    const addValue = (e) => {
        setformValue({ ...value, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await verifyTwoFactorAuthentication(value);
        if (res && res.data) {
            toast.success("loggedIn successfully!");
            setLoading(false);
            encryptToken(res.data?.token);
            localStorage.setItem("email", res.data?.user?.email);
            localStorage.setItem("username", res.data?.user?.username);
            if (res.data?.user?.profile?.first_name) {
                localStorage.setItem("userProfile", true);
                window.location.href = `/community`;
            } else {
                window.location.href = "/auth/complete-profile";
            }
        } else if (res && res?.response) {
            toast.error(res?.response?.data?.message);
            setLoading(false);
        } else {
            toast.info("Oops something went wrong");
            setLoading(false);
        }
    };

    const resendToken = async () => {
        setCounter(60);
        await resendTwoFactorAuthentication({ email });
    };

    const [counter, setCounter] = useState(60);
    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    return (
        <div className="auth signin">
            <header>
                <header>
                    <h1>2-Factor Authentication</h1>
                    <p>
                        A code (4 digit-token) has been sent to your email
                        address <b>{email}</b>, if this is not you please
                        refrain
                    </p>
                </header>
            </header>
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row m-0 mt-5">
                    <div className="col-md-12 p-0 ">
                        <div className="form-group authInput">
                            <label htmlFor={"code"}>Code</label>
                            <input
                                type={"number"}
                                className={`form-control border-0  m_extra text-center largeSpace`}
                                name={"code"}
                                id=""
                                aria-describedby="emailHelpId"
                                placeholder={"Enter Code(e.g 1234)"}
                                onChange={(e) => addValue(e)}
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group authButton">
                    <button
                        className="btn form-control"
                        disabled={isload === true ? true : false}
                        type="submit"
                    >
                        {isload ? "Processing..." : "Verify!"}
                    </button>

                    <button
                        className="btn form-control bg-transparent text-secondary border-0 border-none mt-1 shadow-none link"
                        type="button"
                        disabled={counter === 0 ? false : true}
                        onClick={() => resendToken()}
                    >
                        {counter
                            ? `Resend in ${counter}s`
                            : "I haven't received the code yet. Please resend it"}
                    </button>
                </div>
            </form>

            <div className="extra text-center">
                Return to <Link to="/auth/signin">Login! </Link>
            </div>
        </div>
    );
}

export default TwoFactorAuth;
