import React, { useState } from "react";
import { twoFactorReq } from "../../../utils/request"; // Assuming you have an API request for updating user details
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";

export default function Notification() {
    // State to hold form data
    const auth = useSelector((state) => state.auth?.user);
    // const [loading, setLoading] = useState(false);
    const [following, setFollowing] = useState(false);
    const [follow, setFollow] = useState(false);
    const [newAccount, setNewAccount] = useState(false);
    const [pushNotification, setPushNotification] = useState(false);
    const [directMessage, setDirectMessage] = useState(false);
    const [newPost, setNewPost] = useState(false);

    const updateUserDetails = async (e) => {
        e.preventDefault();
        // setLoading(true); // Show loader

        // Prepare data to send to the API
        try {
            // Send request to the API
            const response = await twoFactorReq();
            // Handle successful details update (e.g., show success message)
            if (response?.data) {
                toast.success(
                    `2Factor Authentication is ${
                        auth?.is_2fa_enabled === 0 ? "Enabled" : "Disabled"
                    } successfully!`
                );
            } else {
                toast.error(
                    response?.response?.data?.message ??
                        `Failed to ${
                            auth?.is_2fa_enabled === 0 ? "Enabled" : "Disabled"
                        }. Please try again.`
                );
            }
        } catch (err) {
            // Handle error (e.g., show error message)
            toast.error(
                `Failed to ${
                    auth?.is_2fa_enabled === 0 ? "Enabled" : "Disabled"
                }. Please try again.`
            );
        } finally {
            // setLoading(false); // Hide loader after request completion
        }
    };

    return (
        <form onSubmit={updateUserDetails}>
            <div className="row col-md-6 col-xl-4 py-4">
                <div className="col-12 py-2">
                    <h5>
                        <b>
                            <b>Mute notification</b>
                        </b>
                    </h5>
                </div>

                <div className="col-12 d-flex mb-3  align-items-center justify-content-between ">
                    <div>From people you don’t follow</div>
                    <div className=" d-flex  align-items-center">
                        <div className="px-3">
                            <InputSwitch
                                checked={following}
                                onChange={(e) => setFollowing(e.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 d-flex mb-3 justify-content-between align-items-center">
                    <div>
                        From people <i className="wi wi-day-showers"></i>ho you
                        don’t follow
                    </div>
                    <div className=" d-flex  align-items-center">
                        <div className="px-3">
                            <InputSwitch
                                checked={follow}
                                onChange={(e) => setFollow(e.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 d-flex mb-3 justify-content-between align-items-center">
                    <div>From People who has a new account</div>
                    <div className=" d-flex  align-items-center">
                        <div className="px-3">
                            <InputSwitch
                                checked={newAccount}
                                onChange={(e) => setNewAccount(e.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 d-flex mb-3 justify-content-between align-items-center">
                    <div>From Direct Message</div>
                    <div className=" d-flex  align-items-center">
                        <div className="px-3">
                            <InputSwitch
                                checked={directMessage}
                                onChange={(e) => setDirectMessage(e.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 d-flex mb-3 justify-content-between align-items-center">
                    <div>From Posts</div>
                    <div className=" d-flex  align-items-center">
                        <div className="px-3">
                            <InputSwitch
                                checked={newPost}
                                onChange={(e) => setNewPost(e.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <hr />

            <div className="row col-md-6 col-xl-4 py-4">
                <div className="col-12 py-2">
                    <h5>
                        <b>
                            <b> Push notification</b>
                        </b>
                    </h5>
                </div>

                <div className="col-12 mb-3">
                    <p>
                        <b>Turn on Push Notification</b> <br />
                        Get push notifications to find out what’s going on when
                        you’re not on Soccernity. You can turn them off anytime.
                        <Link className="text-primary" to="/terms">
                            Learn more
                        </Link>
                    </p>
                    <div className=" d-flex  align-items-center">
                        {/* <div>Off</div> */}
                        <div className="px-0">
                            <InputSwitch
                                checked={pushNotification}
                                onChange={(e) => setPushNotification(e.value)}
                            />
                        </div>
                        {/* <div>On</div> */}
                    </div>
                </div>
            </div>
            <hr />
        </form>
    );
}
