import { useEffect } from "react";
// import Slider from './components/Slider';
import TrendingNews from "../../components/TrendingNews";
import BlogForm from "../Blog/components/Comments/CommentForm";
import Comments from "../Blog/components/Comments/Comments";
import { useDispatch, useSelector } from "react-redux";
import { getSingleBlog } from "../../store/Blog";
import moment from "moment";
import ShareBtn from "../../components/Button/Share";
// import { getCommentsReq } from "../../utils/request";
import SEO from "../../components/SEO/index";
import FirstLoading from "../../components/Loader/FirstLoading";
import Truncate from "react-truncate-html";
import ImagePostCards from "../Community/components/Post/ImagePostCards";
import Footer from "../../layouts/Layout/Footer";
// import renderHTML from 'react-render-html';

/*eslint-disable*/
function SingleBlog() {
    const dispatch = useDispatch();
    const article = useSelector((state) => state.blog?.articles);
    const user = useSelector((state) => state.auth?.user);
    const queryId = window.location.pathname.slice(6);
    useEffect(() => {
        dispatch(getSingleBlog(queryId));
        // dispatch(getCommentsReq(queryId))
    }, []);

    const link = window.location;
    if (!article) return <FirstLoading />;
    return (
        <div className=" pt-5 single-blog">
            <div className="container-fluid">
                <SEO
                    title={article?.title}
                    description={article?.title}
                    link={link}
                />
                <div className="header-single-blog py-md-5">
                    <h1 className="title text-capitalize">{article?.title}</h1>
                    <div className="info row m-0 py-3 align-items-center">
                        <div className="col-md-12 p-0   ">
                            <div className="row m-0 justify-content-center">
                                <div className="col-md-6 p-0 posted ">
                                    <span> Posted By:</span>{" "}
                                    <b> {article?.user?.role}</b>
                                </div>
                                <div className="col-md-6 text-right d-none d-md-block">
                                    <i className="pi pi-clock"></i>{" "}
                                    {moment(article?.created_at).format(
                                        "DD/MM/YYYY hh:mm a"
                                    )}
                                    {/* 08/08/2022 09:28 am */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-4 text-right d-none d-md-block">
                        <span className="mr-3">Share via:</span>
                        <ShareBtn />
                    </div> */}
                    </div>
                </div>
                {/* <Slider article={article} /> */}

                <ImagePostCards image={article?.images} isLarge="true"/>
                <p className="blogText">
                    <Truncate
                        lines={Infinity}
                        dangerouslySetInnerHTML={{
                            __html: article?.body,
                        }}
                        breakWord={true}
                        responsive={true}
                    />
                    {/* {renderHTML(article?.body)} */}
                </p>
                <div>
                    <span className="mr-3">Share via:</span>
                    <ShareBtn />
                </div>
                <div className="commentBlog">
                    <BlogForm
                        user={user}
                        item={article}
                        article_id={article?.id}
                    />

                    {article?.comments?.map((item, key) => (
                        <Comments
                            user={user}
                            item={item}
                            key={key}
                            article_id={article?.id}
                        />
                    ))}
                </div>
                <TrendingNews />
            </div>

            <Footer />
        </div>
    );
}

export default SingleBlog;
