import React, { useState } from "react";
import { changePasswordReq } from "../../../utils/request"; // Import the change password API request
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function ChangePassword() {
    // State to hold form data
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false); // Loader state
    const [error, setError] = useState(""); // Error message state

    const changePassword = async (e) => {
        e.preventDefault();
        // Validate passwords match
        if (newPassword !== confirmPassword) {
            toast.error("New Password and Confirm Password do not match!");
            return;
        }

        setError(""); // Clear error before starting request
        setLoading(true); // Show loader

        // Prepare data to send to the API
        const data = {
            currentPassword,
            newPassword,
            confirmPassword,
        };

        try {
            // Send request to the API
            const response = await changePasswordReq(data);
            // Handle successful password change (e.g., show success message)
            if (response?.data) {
                toast.success("Password changed successfully!");
            } else {
                toast.error(
                    response?.response?.data?.message ??
                        "Failed to change password. Please try again."
                );
            }
        } catch (err) {
            // Handle error (e.g., show error message)
            toast.error("Failed to change password. Please try again.");
        } finally {
            setLoading(false); // Hide loader after request completion
        }
    };

    return (
        <form onSubmit={changePassword}>
            <div className="row col-md-6 col-xl-4 py-4">
                <div className="col-12 py-2">
                    <h5>Change Password</h5>
                </div>
                {error && <div className="col-12 text-danger">{error}</div>}{" "}
                {/* Error message */}
                <div className="col-12">
                    <input
                        type="password"
                        className="form-control py-4"
                        placeholder="Current Password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)} // Update current password state
                        required
                    />
                </div>
                <div className="col-12 mt-3">
                    <input
                        type="password"
                        className="form-control py-4"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)} // Update new password state
                        required
                    />
                </div>
                <div className="col-12 mt-3">
                    <input
                        type="password"
                        className="form-control py-4"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)} // Update confirm password state
                        required
                    />
                </div>
                <div className="col-12 text py-2">
                    <small>
                        Changing your password will log you out of all your
                        active Soccernity page except the one you’re using at
                        this time.{" "}
                        <Link className="text-primary" to="/terms">
                            Learn more
                        </Link>
                    </small>
                </div>
                <div className="col-12 mt-3">
                    <button
                        className="btn btn-blue"
                        type="submit"
                        disabled={loading} // Disable button when loading
                    >
                        {loading // Show loader when loading is true
                            ? "Saving . . . . . . . "
                            : "Save Changes"}
                    </button>
                </div>
            </div>
            <hr />
        </form>
    );
}
