import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import BantCards from "./BantCards";

function SingleBanterHeader() {
    const { singleBants } = useSelector((state) => state.banter);

    const navigate = useNavigate();
    return (
        <div className="singleBanterHeader shadow bg-white mb-4 ">
            <button
                className="fa fa-arrow-left btn"
                onClick={() => navigate(`/banter`)}
            ></button>
            <BantCards item={singleBants?.bant} isSingle={true} />
        </div>
    );
}

export default SingleBanterHeader;
