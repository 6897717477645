/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';

export default function VideoPlayer({ video }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const videoRef = useRef(null);

    const handlePlay = () => {
        setIsPlaying(true);
        // Mute all other videos in the component
        const allPlayers = document.querySelectorAll('.react-player');
        allPlayers.forEach(player => {
            if (player !== videoRef.current) {
                player.muted = true;
            }
        });
    };

    const handlePause = () => {
        setIsPlaying(false);
        // Unmute all other videos when the current video is paused
        const allPlayers = document.querySelectorAll('.react-player');
        allPlayers.forEach(player => {
            if (player !== videoRef.current) {
                player.muted = false;
            }
        });
    };

    return (
        <div className="videoplayer">
            <ReactPlayer
                ref={videoRef}
                url={video}
                width="100%"
                height="400px"
                playing={isPlaying}
                controls={true}
                onPlay={handlePlay}
                onPause={handlePause}
                muted={isMuted}
                poster={video}
            />
        </div>
    );
}
