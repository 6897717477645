import React, { useEffect } from "react";
import BlogCards from "../../components/BlogCards";
// import Categories from './components/Categories'
import Hero from "./components/Hero";
import { useDispatch, useSelector } from "react-redux";
import InputMobile from "./components/HeroInput/InputMobile";
import FirstLoading from "../../components/Loader/FirstLoading";
import { getArticles, getBlog } from "../../store/Blog/index";
import Footer from "../../layouts/Layout/Footer";




function Blog() {
    /* eslint-disable */

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getArticles());
        dispatch(getBlog());



    }, []);
    const blog = useSelector((state) => state.blog?.categories);
    if (!blog) return <FirstLoading />;
    return (
        <div className="Blog">
            <Hero />
            <InputMobile />
            {/* <Categories /> */}
            <div className="container-fluid d-none d-md-block">
                <BlogCards blog={blog} />
            </div>
            <div className="d-block d-md-none ">
                <BlogCards blog={blog} />
            </div>

            <Footer />
        </div>
    );
}

export default Blog;
