import React from "react";
import moment from "moment/moment";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Truncate from "react-truncate-html";
// import Truncate from 'react-truncate';
// import TextTruncate from 'react-text-truncate';
// import ReactHtmlParser from 'react-html-parser';
/* eslint-disable */

function BlogCard({ length }) {
    const navigate = useNavigate();
    const navLink = useLocation().pathname;
    const { id } = useParams();

    const articles = useSelector((state) => state.blog?.allArticles);
    const searchBlog = useSelector((state) => state.blog?.searchBlog);

    const filteredArticles = articles?.filter((article) => {
        if (length && id === article.id)
                return false;
        else if (!searchBlog) return article;
        else
            return (
                article?.title
                    ?.toLowerCase()
                    ?.includes(searchBlog?.toLowerCase()) ||
                article?.category?.name
                    ?.toLowerCase()
                    ?.includes(searchBlog?.toLowerCase()) ||
                false
            );
    });
    const maxLength = 100;
    // const maxLength2 = 100;
    return (
        <div className="pb-3 mobilfluid ">
            <div className={"row m-0 blogcards justify-content-space-around "}>
                {filteredArticles?.length ? (
                    filteredArticles?.slice(0, length)?.map((article, key) => (
                        <div
                            key={key}
                            className={`detailed-cards col-md-4     my-3`}
                            data-aos="fade-up"
                            data-aos-duration="1000"
                        >
                            <div className="img ">
                                <img
                                    src={article?.images[0]?.url}
                                    alt=""
                                    className="imgBlogcard shadow  link"
                                    onClick={() =>
                                        (window.location.href = `/blog/${article?.id}`)
                                    }
                                />
                            </div>

                            <div className="card-body   px-0 radius-5">
                                {
                                    <small className="tag shadow ">
                                        {article?.category?.name}
                                    </small>
                                }
                                <h3 className="titleText">
                                    <a href={`/blog/${article?.id}`}>
                                        {article?.title?.length >= maxLength
                                            ? article?.title.slice(
                                                  0,
                                                  maxLength
                                              ) + " ..."
                                            : article?.title}
                                    </a>
                                </h3>
                                <p className="body ">
                                    {/* {ReactHtmlParser((article?.body?.length >= maxLength2) ? article?.body.slice(0, maxLength2) + ' ...' : article?.body)} */}
                                    <Truncate
                                        lines={4}
                                        dangerouslySetInnerHTML={{
                                            __html: article?.body,
                                        }}
                                        breakWord={true}
                                        responsive={true}
                                    />
                                    <a
                                        href={`/blog/${article?.id}`}
                                        className="smallReadMore"
                                    >
                                        {" "}
                                        Read More
                                    </a>
                                </p>
                                <p className="time d-flex align-items-center">
                                    <i className="pi pi-clock"></i>
                                    <span className="ml-2">
                                        {moment(article?.created_at).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </span>
                                </p>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="p-5  m-auto ">No matching result!</div>
                )}
            </div>

            {navLink !== "/blog" && (
                <div className="loadMore col">
                    <button
                        className="loadMore-btn"
                        onClick={() => navigate("/blog")}
                    >
                        See More
                    </button>
                </div>
            )}
        </div>
    );
}

export default BlogCard;
