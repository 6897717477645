import React, { useState } from "react";
import { toast } from "react-toastify";
import { createBanterReq } from "../../../utils/request";
import { gePost } from "../../../store/Community";
import { useDispatch } from "react-redux";
import { Editor } from "primereact/editor";
import { Badge } from "primereact/badge";

function CreateBanter({ auth, changeVisibility }) {
    const dispatch = useDispatch();
    // const initialValue = {
    //     topic: "",
    //     description: "",
    // };
    const [topic, setTopic] = useState("");
    const [editorValue, setEditorValue] = useState("");
    const [isload, setLoading] = useState(false);
    // const [img, setImg] = useState([]);
    const [photos, setPhotos] = useState([]);
    // const addValue = (e) => {
    //     setformValue({ ...value, [e.target.name]: e.target.value });
    // };
    const formData = new FormData();
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        //close modal toggle
        changeVisibility(false);
        for (let i = 0; i < photos.length; i++) {
            formData.append(`photos[${i}]`, photos[i]);
        }
        formData.append(`topic`, topic);
        formData.append(`description`, editorValue ?? " ");
        const res = await createBanterReq(formData);

        toast.update("toastId.current", {
            render: "Your message...",
            type: "success",
            isLoading: "false",
        });

        if (res && res.data) {
            setTopic("");
            setEditorValue("");
            setLoading(false);
            dispatch(gePost());
            // ModalClose("loadPopup")
            // window.location.reload()
        } else if (res && res?.response) {
            toast.error(res?.response?.data?.message);
            setLoading(false);
        } else {
            toast.info("Oops something went wrong");
            setLoading(false);
        }
    };
    const [previewUrls, setPreviewUrls] = useState([]);

    const addImage = (e) => {
        let input = e.target.files;
        setPhotos(input);
        const selected = Array.from(input);
        const newPreviewUrls = selected.map((file) =>
            URL.createObjectURL(file)
        );
        setPreviewUrls(newPreviewUrls);
    };

    const removeImage = (indexToRemove) => {
        setPreviewUrls((prevUrls) =>
            prevUrls?.filter((url, index) => index !== indexToRemove)
        );

        setPhotos((photos) =>
            Array.from(photos)?.filter((url, index) => index !== indexToRemove)
        );
    };
    return (
        <form className="postBox banter-form" onSubmit={(e) => handleSubmit(e)}>
            <div className="row m-0">
                <div className="col-2 text-center p-0 d-none d-md-block">
                    <img
                        src={auth?.profile?.profile_photo}
                        alt=""
                        className="profile-large-avatar"
                    />
                </div>
                <div className="col-md-9 p-md-0">
                    <input
                        name="topic"
                        type="text"
                        className="form-control mb-4 border-primary bg-white"
                        placeholder="Title"
                        onChange={(e) => setTopic(e.target.value)}
                        value={topic}
                    />
                    <div className="editorBox">
                        <Editor
                            focus
                            value={editorValue}
                            onTextChange={(e) => setEditorValue(e.htmlValue)}
                            className="text_Editor shadow-sm"
                        />
                    </div>
                    <div className="previewPost">
                        {previewUrls?.length <= 5 &&
                            previewUrls.map((url, index) => (
                                <div className="mt-3">
                                    <Badge
                                        value="x"
                                        severity="danger"
                                        className="removePix"
                                        onClick={() => removeImage(index)}
                                    ></Badge>
                                    <img
                                        key={index}
                                        src={url}
                                        alt="Preview"
                                        className="shadow "
                                    />
                                </div>
                            ))}
                    </div>
                    {photos?.length > 5 ? (
                        <small className="text-danger ">
                            Image should not exceeds 5
                        </small>
                    ) : (
                        ""
                    )}
                    <div className="postBottom">
                        <div className="f-img">
                            <div className="file-input">
                                <input
                                    type="file"
                                    name="file-input"
                                    id="file-input"
                                    className="file-input__input"
                                    multiple
                                    accept="image/png,image/jpg,image/png,image/jpeg"
                                    maxLength={5}
                                    onChange={(e) => addImage(e)}
                                />
                                <label
                                    className="file-input__label"
                                    for="file-input"
                                >
                                    <span>
                                        Add to your post
                                        <span className="ml-3">
                                            {" "}
                                            <img
                                                src="/images/uploadImage.png"
                                                alt=""
                                            />
                                            <img
                                                src="/images/uploadVideo.png"
                                                alt=""
                                            />
                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="s-img">
                            <button
                                className="btn add-post "
                                disabled={
                                    isload === true || photos?.length > 5
                                        ? true
                                        : false
                                }
                            >
                                {" "}
                                {isload ? "Posting..." : "Post"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default CreateBanter;
