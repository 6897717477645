import React, { useEffect, useState } from "react";
import EditProfile from "./EditProfile";
// import Messages from '../../../Chats/components/Messages';
// import { openIschat } from '../../../../store/Chat';
import { useDispatch } from "react-redux";
import { gePost } from "../../../../store/Community";
import { useNavigate } from "react-router-dom";
// import FollowsInfo from './FollowsInfo';

import { Image } from "primereact/image";
import { followReq } from "../../../../utils/request";
import CopyProfile from "./CopyProfile";

/*eslint-disable*/
function ViewProfile({ profile, auth, history }) {
    const [isfollow, setIsFollow] = useState(false);
    const [title, setTitle] = useState("");
    const user = profile;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const location = useLocation();
    // const his = useHistory();
    useEffect(() => {
        dispatch(gePost());
    }, []);
    const followFunc = async (following_id) => {
        setIsFollow(!isfollow);

        const res = await followReq({ following_id });
        if (res && res.data) {
            toast.success(res?.data?.message);
        } else if (res && res?.response) {
            toast.error(res?.response?.data?.message);
        } else {
            toast.info("Oops something went wrong");
        }
    };
    const url = user?.profile?.profile_photo ?? "/images/profile/profile.png";
    return (
        <>
            {/* {<Messages profile={profile} />} */}
            <div className="viewProfile ">
                <div className="card shadow">
                    <img
                        className="card-img-top profilecoverPhoto"
                        src={
                            user?.profile?.cover_photo ??
                            "/images/profile/defaultbg.png"
                        }
                        alt=""
                    />
                    <div className="card-body">
                        <Image
                            src={url}
                            zoomSrc={url}
                            alt={""}
                            width="100%"
                            height="100%"
                            preview
                            indicatorIcon={
                                <i className="pi pi-eye text-white"></i>
                            }
                            className="avatar shadow-sm"
                            loading="eager"
                            imageStyle={{
                                border: "3px solid #FFFFFF",
                                borderRadius: "50%",
                            }}
                        />

                        <div className="row m-0 ">
                            <div className="col-md-12 p-0 text-right firstCol">
                                {auth?.id === user?.id ? (
                                    <div className="isAuth ">
                                        <CopyProfile history={history} />
                                        <EditProfile auth={auth} />
                                    </div>
                                ) : (
                                    <div className="isGuest ">
                                        <CopyProfile history={history} />
                                        <button
                                            className="btn lightHover d-none d-md-inline"
                                            onClick={() =>
                                                navigate(
                                                    `/chats?user=${user?.username}&id=${user?.id}`
                                                )
                                            }
                                        >
                                            <img
                                                src="/images/profile/msg.png"
                                                alt=""
                                            />
                                        </button>
                                        <button
                                            className="btn lightHover d-md-none d-inline"
                                            onClick={() =>
                                                navigate(
                                                    `/chat?user=${user?.username}&id=${user?.id}`
                                                )
                                            }
                                        >
                                            <img
                                                src="/images/profile/msg.png"
                                                alt=""
                                            />
                                        </button>

                                        <button
                                            className="btn btn-blue-btn shadow"
                                            onClick={() => followFunc(user?.id)}
                                        >
                                            {user?.is_followed
                                                ? "Unfollow"
                                                : "Follow"}
                                        </button>
                                    </div>
                                )}
                            </div>

                            <div className="col-md-12 text-box">
                                <h4 className="fullname">
                                    {user?.profile?.first_name}{" "}
                                    {user?.profile?.last_name}
                                    {/* <span className="isclub"><img src="/images/profile/isclub.png" alt="" /></span> */}
                                    <span className="location">
                                        <img
                                            src="/images/profile/location.png"
                                            alt=""
                                        />
                                        {user?.profile?.location}{" "}
                                    </span>
                                </h4>
                                <p className="username">@{user?.username}</p>
                                <p className="bio">{user?.profile?.bio} </p>

                                {user?.profile?.club && (
                                    <p className="font-weight-bold">
                                        {user?.profile?.club} Fan{" "}
                                    </p>
                                )}

                                <p className="follows ">
                                    <span
                                        className="info ml-0 followsInfo"
                                        onclick={() => setTitle("Followers")}
                                    >
                                        {user?.followers_count}
                                        <span
                                            className="details"
                                            onClick={() =>
                                                navigate(
                                                    `/profile?user=${user?.email}&type=followers`
                                                )
                                            }
                                        >
                                            Followers
                                        </span>
                                    </span>
                                    <div className="dotDivider"></div>
                                    <span
                                        className="info followsInfo"
                                        onclick={() => setTitle("Following")}
                                    >
                                        {user?.followings_count}
                                        <span
                                            className="details "
                                            onClick={() =>
                                                navigate(
                                                    `/profile?user=${user?.email}&type=following`
                                                )
                                            }
                                        >
                                            Following
                                        </span>
                                    </span>
                                </p>

                                {/* <FollowsInfo title={title} data={user} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ViewProfile;
