import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getChat } from "../../../store/Chat/index";
import { useDispatch, useSelector } from "react-redux";
import HeaderMessage from "./HeaderMessage";
import Messages from "./Messages";
import ChatForm from "./ChatForm";
import { getProfile } from "../../../store/Auth";
import { qParams } from "../../../utils/getQueryParams";

function ChatsMessage() {
    const location = useLocation();
    const dispatch = useDispatch();
    const link = location.search;
    const user = qParams("user", link);
    const id = qParams("id", link);
    /* eslint-disable */

    useEffect(() => {
        dispatch(getProfile(user));
        dispatch(getChat(id));

        const intervalId = setInterval(() => {
            dispatch(getChat(id));
        }, 5000); // Refresh every 5 seconds (5000 milliseconds)

        return () => {
            clearInterval(intervalId); // Cleanup the interval on component unmount
        };
    }, [user]);

    // useState
    const chats = useSelector((state) => state.chat?.chats);
    const auth = useSelector((state) => state.auth?.user);
    const profile = useSelector((state) => state.auth?.profile);

    return (
        <div className="ChatsMessage d-md-block d-none">
            <div className="chatMessages ">
                {/* header */}
                {user && (
                    <HeaderMessage chat={chats} auth={auth} profile={profile} />
                )}

                {/* message */}
                {chats.length === 0 ? (
                    <div className="chatNotAvailable">
                        <img src="/images/nochat.png" alt="" />
                    </div>
                ) : (
                    <Messages chat={chats} auth={auth} profile={profile} />
                )}

                {/* input */}
                {user && (
                    <ChatForm chat={chats} auth={auth} profile={profile} />
                )}
            </div>
        </div>
    );
}

export default ChatsMessage;
