import React, { useEffect, useState } from "react";
import { deactivateAccountReq } from "../../../utils/request"; // Assuming you have an API request for updating user details
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function DeactivateAccount() {
    // State to hold form data
    const auth = useSelector((state) => state.auth?.user);
    const [password, setPassword] = useState(null);
    const [isNext, setIsNext] = useState(false);
    // const [email, setEmail] = useState(auth?.email);
    const [loading, setLoading] = useState(false); // Loader state
    useEffect(() => {}, []);
    const updateUserDetails = async (e) => {
        e.preventDefault();
        setLoading(true); // Show loader

        // Prepare data to send to the API
        const data = {
            password,
        };

        try {
            // Send request to the API
            const response = await deactivateAccountReq(data);
            // Handle successful details update (e.g., show success message)
            if (response?.data) {
                toast.success("Account details Deactivated successfully!");
            } else {
                toast.error(
                    response?.response?.data?.message ??
                        "Failed to deactivate account details. Please try again."
                );
            }
        } catch (err) {
            // Handle error (e.g., show error message)
            toast.error(
                "Failed to deactivate account details. Please try again."
            );
        } finally {
            setLoading(false); // Hide loader after request completion
        }
    };

    return (
        <form onSubmit={updateUserDetails}>
            <div className="row col-md-6 col-xl-4 py-4">
                <div className="col-12 py-2">
                    <h5>
                        <b>Deactivate Account</b>
                    </h5>
                </div>

                {isNext === false ? (
                    <>
                        <div className="col-12 mb-3">
                            <p>
                                Deactivate Account You’re about to start the
                                process of deactivating your account. Your
                                display name,
                                <b>@{auth?.username}</b> , and public profile
                                will no longer be viewable on soccernity.com
                            </p>
                            <p>
                                <b> What to know</b> <br />
                                You can restore your soccernity account if it
                                was accidentally or wrongfully deactivated for
                                up to 30 days after deactivation. Deactivate
                            </p>
                        </div>

                        <div className="col-12 mt-1">
                            <button
                                className="btn btn-danger"
                                type="button"
                                onClick={() => setIsNext(true)}
                            >
                                Deactivate
                            </button>
                        </div>
                    </>
                ) : (
                    <div className="col-12 mt-1">
                        <p>
                            Hello <b>@{auth?.username}</b>, are you sure you
                            want to Deactivate your account?
                        </p>
                        <p>
                            <input
                                type="password"
                                className="form-control py-4"
                                placeholder="Enter your Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} // Update new password state
                                required
                            />
                        </p>
                        <button
                            className="btn btn-danger"
                            type="button"
                            onClick={() => setIsNext(false)}
                        >
                            No, Cancel
                        </button>
                        <button
                            className="btn btn-blue ml-2"
                            type="submit"
                            disabled={loading} // Disable button when loading
                        >
                            {loading // Show loader when loading is true
                                ? "Deactivating . . . . . . . "
                                : "Yes, Deactivate"}
                        </button>
                    </div>
                )}
            </div>
            <hr />
        </form>
    );
}
