import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import CreateLeaderboard from "../../../LeaderBoard/components/CreateLeaderboard";
import CreatePost from "./CreatePost";
import { Dialog } from "primereact/dialog";

const PostForm = ({ auth }) => {
    // const checkPaticipant = useSelector(
    //     (state) => state.leaderboard.paticipateCheck
    // );
    const [visible, setVisible] = useState(false);

    return (
        <>
            <div className="col-10 col-md-9 p-md-0 ">
                <input
                    name=""
                    readOnly
                    placeholder="What’s happening?"
                    data-toggle="modal"
                    data-target="#loadPopup"
                    onClick={() => setVisible(true)}
                />
            </div>
            <Dialog
                header=""
                visible={visible}
                style={{ width: "70vw", borderRadius: 12 }}
                closable={true}
                onHide={() => setVisible(false)}
                headerClassName="p-0"
                focusOnShow={false}
            >
                <div className="modal-body cratePostTab p-0  ">
                    {/* pull in tab */}
                    <ul
                        className="nav nav-pills   mb-3 border-bottom "
                        id="pills-tab"
                        role="tablist"
                    >
                        <li className="nav-item">
                            <a
                                className="nav-link active link"
                                id="pills-home-tab"
                                data-toggle="pill"
                                href="#pills-home"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                            >
                                Create a Post
                            </a>
                        </li>

                        {/* <li className="nav-item">
                            <a
                                className="nav-link link"
                                id="pills-profile-tab"
                                data-toggle="pill"
                                href="#pills-profile"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                            >
                                Leaderboard
                                <span className="badge badge-primary bg-muted">
                                    {checkPaticipant?.alreadyParticipated
                                        ? 0
                                        : 1}
                                </span>
                            </a>
                        </li> */}
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                        >
                            {/* {visible} */}
                            <CreatePost
                                auth={auth}
                                changeVisibility={(e) => setVisible(e)}
                            />
                        </div>
                        {/* {checkPaticipant?.leaderboard?.status !== true && (
                            <div
                                className="tab-pane fade"
                                id="pills-profile"
                                role="tabpanel"
                                aria-labelledby="pills-profile-tab"
                            >
                                <CreateLeaderboard
                                    auth={auth}
                                    changeVisibility={(e) => setVisible(e)}
                                    checkPaticipant={checkPaticipant}
                                />
                            </div>
                        )} */}
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default PostForm;
