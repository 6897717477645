import React, { useState } from "react";
import {
    updatProfilePhotoReq,
    updatProfileReq,
    updateCoverPhotoReq,
} from "../../../../utils/request";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getProfile, getUser } from "../../../../store/Auth";
import { qParams } from "../../../../utils/getQueryParams";
import { useLocation } from "react-router-dom";

import { Dialog } from "primereact/dialog";

function EditProfile({ auth }) {
    const [data, setformData] = useState({ ...auth?.profile });
    const [visible, setVisible] = useState(false);

    const [img, setImg] = useState(data?.profile_photo || "/images/upload.png");
    const [coverImage, setCoverImg] = useState("/images/upload.png");

    const dispatch = useDispatch();
    const location = useLocation();
    const user = qParams("user", location.search);

    const [error, setError] = useState();
    const [isload, setLoading] = useState(false);
    const addValue = (e) => {
        const { name, value } = e.target;
        setformData({ ...data, [name]: value });
        setError(null);
    };
    const addPicture = async (e) => {
        var formData = new FormData();
        let input = e.target;
        let reader = new FileReader();
        reader.onload = (res) => {
            setImg(res.target.result);
        };
        reader.readAsDataURL(input.files[0]);

        formData.append("profile_photo", input.files[0]);
        const res = await updatProfilePhotoReq({
            profile_photo: input.files[0],
        });
        if (res && res.data) {
            toast.success(res?.data?.message);
            setLoading(false);
            dispatch(getUser());
            dispatch(getProfile(user));
        } else if (res && res?.response) {
            toast.error(res?.response?.data?.message);
            setError(res?.response?.data?.errors);
            setLoading(false);
        } else {
            toast.info("Oops something went wrong");
            setLoading(false);
        }
    };
    const addCoverPhoto = async (e) => {
        var formData = new FormData();
        let input = e.target;
        let reader = new FileReader();
        reader.onload = (res) => {
            setCoverImg(res.target.result);
        };
        reader.readAsDataURL(input.files[0]);

        formData.append("cover_photo", input.files[0]);
        const res = await updateCoverPhotoReq({
            cover_photo: input.files[0],
        });
        if (res && res.data) {
            toast.success(res?.data?.message);
            setLoading(false);
            dispatch(getUser());
            dispatch(getProfile(user));
        } else if (res && res?.response) {
            toast.error(res?.response?.data?.message);
            setError(res?.response?.data?.errors);
            setLoading(false);
        } else {
            toast.info("Oops something went wrong");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        const res = await updatProfileReq(data);
        if (res && res.data) {
            toast.success(res?.data?.message);
            setLoading(false);
            // window.location.reload();
        } else if (res && res?.response) {
            toast.error(res?.response?.data?.message);
            setError(res?.response?.data?.errors);
            setLoading(false);
        } else {
            toast.info("Oops something went wrong");
            setLoading(false);
        }
        setVisible(false);
    };
    //    if(showModal)
    return (
        <>
            <button
                className="btn btn-blue-btn shadow"
                onClick={() => setVisible(true)}
            >
                Edit Profile
            </button>
            <Dialog
                header="Edit Profile"
                visible={visible}
                style={{ width: "50vw" }}
                onHide={() => setVisible(false)}
            >
                <div className="modal-body p-0">
                    <div
                        className="imgbox_container "
                        style={{
                            backgroundImage: `url(${
                                data?.cover_photo ?? coverImage
                            })`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            height: "247px",
                            border: "1px solid grey",
                        }}
                    >
                        <div className="img">
                            <input
                                type={"file"}
                                className={`uploadFileInput uploadFileInput2 my-2 border`}
                                onChange={(e) => addCoverPhoto(e)}
                                value=""
                                name="profile_photo"
                                title="Upload Profile image "
                                accept="image/*,image/heic"
                                style={{
                                    backgroundImage: `url(${"/images/profile/add.png"})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                }}
                            />
                        </div>
                    </div>

                    <div className="avatarBox mb-5">
                        <input
                            type={"file"}
                            className={`uploadFileInput my-2 border`}
                            onChange={(e) => addPicture(e)}
                            value=""
                            name="profile_photo"
                            title="Upload Profile image "
                            accept="image/*,image/heic"
                            style={{
                                backgroundImage: `url(${img})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                            }}
                        />

                        <div className="text-center">
                            <small className="text-muted">
                                Click on the Image*
                            </small>
                        </div>
                    </div>

                    <form
                        action=""
                        className=" text-left editProfileForm mt-5 "
                        onSubmit={(e) => handleSubmit(e)}
                    >
                        <div className="form-group mt-5 mt-md-2">
                            <label for="name" className="mb-0 mt-3 ">
                                Full Name
                            </label>
                            <div className="row">
                                <div className="col-md-6 mt-3 m-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name={"first_name"}
                                        id=""
                                        aria-describedby="emailHelpId"
                                        placeholder={"First Name"}
                                        onChange={(e) => addValue(e)}
                                        required
                                        value={data?.first_name}
                                    />
                                    {error?.first_name && (
                                        <small
                                            id="emailHelpId"
                                            className="form-text text-danger"
                                        >
                                            {error?.first_name[0]}
                                        </small>
                                    )}
                                </div>
                                <div className="col-md-6 mt-3 m-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name={"last_name"}
                                        id=""
                                        aria-describedby="emailHelpId"
                                        placeholder={"Last Name"}
                                        onChange={(e) => addValue(e)}
                                        required
                                        value={data?.last_name}
                                    />
                                    {error?.last_name && (
                                        <small
                                            id="emailHelpId"
                                            className="form-text text-danger"
                                        >
                                            {error?.last_name[0]}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <label for="location">Location</label>
                            <input
                                type="text"
                                className="form-control"
                                name={"location"}
                                id=""
                                aria-describedby="emailHelpId"
                                placeholder={"Enter your location"}
                                onChange={(e) => addValue(e)}
                                value={data?.location}
                            />
                            {error?.location && (
                                <small
                                    id="emailHelpId"
                                    className="form-text text-danger"
                                >
                                    {error?.location[0]}
                                </small>
                            )}
                        </div>
                        <div className="form-group ">
                            <label for="location">Gender</label>
                            <select
                                type="text"
                                className="form-control"
                                name={"gender"}
                                id="gender"
                                aria-describedby="emailHelpId"
                                placeholder={"Enter your location"}
                                onChange={(e) => addValue(e)}
                                value={data?.gender}
                            >
                                <option>Male</option>
                                <option>Female</option>
                                <option>Others</option>
                            </select>
                          
                            {error?.gender && (
                                <small
                                    id="emailHelpId"
                                    className="form-text text-danger"
                                >
                                    {error?.gender[0]}
                                </small>
                            )}
                        </div>

                        <div className="form-group">
                            <label for="bio">Bio</label>
                            <textarea
                                name="bio"
                                id=""
                                cols="30"
                                rows="10"
                                placeholder="Write a Bio"
                                className="form-control"
                                onChange={(e) => addValue(e)}
                                maxLength="250"
                                value={data?.bio}
                            ></textarea>
                            <small className="text-muted ml-2">
                                {data?.bio?.length ?? 0}/250
                            </small>

                            {error?.bio && (
                                <small
                                    id="emailHelpId"
                                    className="form-text text-danger"
                                >
                                    {error?.bio[0]}
                                </small>
                            )}
                        </div>
                        <div className="form-group">
                            <label for="">Preferred Club </label>
                            <input
                                type="text"
                                className="form-control"
                                name={"club"}
                                id=""
                                aria-describedby="emailHelpId"
                                placeholder={"Verify your team"}
                                onChange={(e) => addValue(e)}
                                required
                                value={data?.club}
                            />
                            {error?.club && (
                                <small
                                    id="emailHelpId"
                                    className="form-text text-danger"
                                >
                                    {error?.club[0]}
                                </small>
                            )}
                        </div>
                        <div className="form-group text-right">
                            <button
                                className="btn btn btn-blue "
                                disabled={isload === true ? true : false}
                                type="submit"
                            >
                                {isload ? "Updating..." : "Update Profile"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    );
}

export default EditProfile;
