import React from "react";
import { useSelector } from "react-redux";

function LastWeekLeaderBoard() {
    // const array = [1, 2, 3, 4, 5, 6, 7,8,9,10]

    const LastWeekTop = useSelector((state) => state.leaderboard?.LastWeekTop);
//   console.debug(LastWeekTop)
    return (
        <div className="LastWeekLeaderBoard shadow">
            <span className="badge badge-primary">Previous Challenge</span>
            <h5>Leaderboard</h5>
            <div className="stat">
                <div className="stat_header">
                    <div className="username">Username</div>
                    <div className="point">Points</div>
                </div>
                {LastWeekTop?.length &&
                    LastWeekTop?.map((items, key) => (
                        <div className="stat_body" key={key}>
                            <div className="username">
                                {items?.user?.username}
                            </div>
                            <div className="point">{items?.votes_count}</div>
                        </div>
                    ))}

                {!LastWeekTop?.length && (
                    <div className="p-4 my-4">
                        <h6>No available Challenge</h6>
                    </div>
                )}
            </div>
        </div>
    );
}

export default LastWeekLeaderBoard;
