import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
    addCommunityLikeReq,
    deleteCommunityCommentsReq,
} from "../../../../utils/request";
import { gePost } from "../../../../store/Community";
import ReplyCard from "../Replies/ReplyCard";
import ReplyForm from "../Replies/ReplyForm";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

function CommentsCard({ item, user }) {
    const dispatch = useDispatch();
    const [isload, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState(null); // Track the ID of the comment to be deleted
    const [showDeleteDialog, setShowDeleteDialog] = useState(false); // Modal visibility state

    // Delete comment API logic (to be added later)
    const deleteComment = async (commentId) => {
        console.log(`Deleting comment with id: ${commentId}`);
        // Add your delete API call here
        // dispatch(gePost()); // Update posts if necessary
        setShowDeleteDialog(false); // Close modal after delete
        await deleteCommunityCommentsReq(commentId, {});
    };

    // Show the delete dialog
    const handleDeleteClick = (commentId) => {
        setDeleteId(commentId);
        setShowDeleteDialog(true); // Show delete modal
    };

    // Handle the like action
    const likeFunc = async (id) => {
        setLoading(true);
        const res = await addCommunityLikeReq(id);
        if (res && res.data) {
            dispatch(gePost());
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    return (
        <div className="CommentsCard ">
            <div className="row-container row mb-4 pb-3">
                <div>
                    <img
                        src={
                            item?.user?.profile?.profile_photo ??
                            `/images/emeka.png`
                        }
                        alt=""
                        className="profile-avatar"
                    />
                </div>
                <div className="body-container ml-3">
                    <h4 className="username">@{item.user?.username}</h4>
                    <p className="body">{item?.body}</p>
                    <div className="loveLike">
                        {/* {console.debug(item?.user?.id + " " + user?.id)} */}

                        {item?.user?.id === user?.id ? (
                            <span className="imgMiniBox ml-3">
                                <span
                                    className="link"
                                    onClick={() => handleDeleteClick(item?.id)}
                                >
                                    <i className="fa fa-trash-o" alt=""></i>
                                </span>
                            </span>
                        ) : (
                            ""
                        )}
                        <span className="imgMiniBox ml-5">
                            <a
                                data-toggle="collapse"
                                href={`#collapseComment${item?.id}`}
                                aria-expanded="false"
                                aria-controls="collapseComment"
                            >
                                <img src="/images/box/reply.png" alt="" />
                            </a>
                            <span>
                                {item?.replies?.length > 0
                                    ? item?.replies?.length
                                    : ""}
                            </span>
                        </span>
                        <span className="imgMiniBox">
                            {item?.likes?.find(
                                (isLike) => isLike?.user_id === user?.id
                            ) || isload ? (
                                <img
                                    onClick={() => likeFunc(item?.id)}
                                    src="/images/box/lovep.png"
                                    alt=""
                                />
                            ) : (
                                <img
                                    onClick={() => likeFunc(item?.id)}
                                    src="/images/box/love.png"
                                    alt=""
                                />
                            )}
                            {item?.likes?.length > 0 && (
                                <span> {item?.likes?.length}</span>
                            )}
                        </span>
                    </div>
                </div>
            </div>

            {/* Delete confirmation modal */}
            <Dialog
                header="Delete Confirmation"
                visible={showDeleteDialog}
                style={{ width: "370px" }}
                modal
                footer={
                    <div>
                        <Button
                            label="No"
                            icon="pi pi-times"
                            onClick={() => setShowDeleteDialog(false)}
                            className="p-button-text"
                        />
                        <Button
                            label="Yes"
                            icon="pi pi-check"
                            onClick={() => deleteComment(deleteId)}
                            autoFocus
                        />
                    </div>
                }
                onHide={() => setShowDeleteDialog(false)}
            >
                <p className="px-2">
                    Are you sure you want to delete this comment?
                </p>
            </Dialog>

            <div
                className="collapse col-md-11 ml-auto mt-3"
                id={`collapseComment${item?.id}`}
            >
                <ReplyForm
                    item={item}
                    user={user}
                    parent_id={item?.id}
                    body={item?.user?.username}
                />
            </div>

            {/* All replies here */}
            <div className="replies pl-3 mt-2">
                {item?.replies?.map((reply, key) => (
                    <ReplyCard
                        item={reply}
                        key={key}
                        user={user}
                        comment_id={item?.id}
                    />
                ))}
            </div>
        </div>
    );
}

export default CommentsCard;
