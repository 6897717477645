import moment from 'moment';
import React from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Truncate from "react-truncate-html";

import LoadBanner from '../../../../Loaders/LoadBanner';
function TrendingNews() {
    const allArticles = useSelector((state) => state.blog.allArticles);
    const navigate = useNavigate();
    return (
        <div className="trending_news headbox border">
            <div className="header">
                <span> Trending News </span>
                <LoadBanner type={"trendingNews"} />
            </div>
            <div className="postNews">
                {allArticles?.slice(0, 3)?.map((item, key) => (
                    <div className="row m-0" key={key}>
                        <div className="col-4 p-0 ">
                            <img src={item?.images[0]?.url} alt="" />
                        </div>
                        <div className="col-8 p-0">
                            <h3
                                className="links"
                                onClick={() => navigate(`/blog/${item?.id}`)}
                            >
                                {item?.title}
                            </h3>
                            <p className="body ">
                                {/* {ReactHtmlParser((article?.body?.length >= maxLength2) ? article?.body.slice(0, maxLength2) + ' ...' : article?.body)} */}
                                <Truncate
                                    lines={4}
                                    dangerouslySetInnerHTML={{
                                        __html: item?.body,
                                    }}
                                    breakWord={true}
                                    responsive={true}
                                />
                                <a
                                    href={`/blog/${item?.id}`}
                                    className="smallReadMore"
                                >
                                    {" "}
                                    Read More
                                </a>
                            </p>
                            <p className="time">
                                <img src="/images/time.png" alt="" />
                                <span className="ml-3">
                                    {moment(item?.created_at).format(
                                        "DD/MM/YYYY"
                                    )}
                                </span>
                            </p>
                        </div>
                        <div className="col-12 p-0 m-0">
                            <hr className="p-0 m-0" />
                        </div>
                    </div>
                ))}
                <div className="text-center  p-2">
                    <Link to="/blog" className="link-btn btn">
                        See more
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default TrendingNews
