import React, { useEffect } from 'react'
import EntriesHeader from './components/EntriesHeader'
import VideoPlayer from '../Community/components/Post/VideoPlayer'
import { useLocation } from 'react-router'
import { useSelector, useDispatch } from 'react-redux';
import VoteSummary from './components/VoteSummary';
import { getLeaderSingleBoard } from '../../store/LeaderBoard';
import LastWeekLeaderBoard from './components/LastWeekLeaderBoard';

/* eslint-disable */


function SingleEntry() {

    const route = useLocation();
    const newRoutes = route.pathname?.replace("/leaderboard/", '');
    const details = useSelector((state) => state.leaderboard?.leaderboard_single_challange?.leaderBoardChallenge)
    const auth = useSelector((state) => state.auth?.user)
    const dispatch=useDispatch()
    useEffect(() => {
        dispatch(getLeaderSingleBoard(newRoutes));
        const intervalId = setInterval(() => {

            dispatch(getLeaderSingleBoard(newRoutes));

    }, 5000); // Run every 5 seconds (5000 milliseconds)

    // Clean up function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
    }, [])

    if (details)
    return (
        <div className='Entry'>
            <div className="container-fluid">
                <EntriesHeader />

                <div className="row">
                    <div className="col-md-8 mr-auto">
                        <VideoPlayer video={details?.video} />
                    </div>
                    <div className="col-md-3 ">
                        <LastWeekLeaderBoard/>
                    </div>
                </div>

                <div className='contestantBoard text-center '>
                    <VoteSummary data={details} newRoutes={newRoutes} auth={auth}/>
                </div>


            </div>
        </div>
    )
}

export default SingleEntry
