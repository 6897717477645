import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { unReadNotification,getNotification } from "../../utils/request";

export const getNotify = createAsyncThunk(
    'notification/getNotify',
    async (payload) => {
        const res = await getNotification(payload);
        return res.data?.notifications;
    }
)

export const getNotificationCount = createAsyncThunk(
    'notification/getNotificationCount',
    async (payload) => {
        const res = await unReadNotification(payload);
        if(res && res?.data){
            return res.data;
        }
        else if(res?.response?.status===401){
        //    localStorage.removeItem("token")
        //     const token=localStorage.getItem("token")
        //     if(!token && window.location.pathname!=='/auth/signin'){
        //        window.location.href = "/auth/signin"
        //     }
        }

    }
)

export const notification = createSlice(
    {
        name: "notification",
        initialState: {
        },
        notification: "",
        notificationCount: "",
        chatCount: "",
        reducers: {

        },
        extraReducers: (builder) => {
            builder.addCase(getNotify.fulfilled, (state, action) => {
                state.notification = action.payload
            })
            builder.addCase(getNotificationCount.fulfilled, (state, action) => {
                state.notificationCount = action.payload.unreadNotificationsCount
                state.chatCount = action.payload.unreadMessageNotificationsCount
            })

        }

    });



export default notification.reducer;
