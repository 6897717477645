/*eslint-disable*/

import React, { useEffect, useState } from "react";
import Profile from "../components/Profile";
import SuggestedFreinds from "../components/SuggestedFreinds";
import FixturesSub from "../components/Fixtures";
import TrendingNews from "../components/TrendForYou/TrendingNews";
import TrendForYou from "../components/TrendForYou/TrendForYou";
import PostBlog from "../components/Post/PostBlog";
import Postcards from "../components/Post/Postcards";
import { useDispatch, useSelector } from "react-redux";
import { gePost, getSelectedPost } from "../../../store/Community";
import PostLoader from "../../../Loaders/PostLoader";
import { useLocation, useParams } from "react-router-dom";
import { qParams } from "../../../utils/getQueryParams";

function SelectedPost() {
    const posts = useSelector((state) => state.community?.selectedPost);
    const dispatch = useDispatch();
    const location = useLocation();
    const [history, setHistory] = useState(window.location);
    const auth = useSelector((state) => state.auth?.user);
    const {id} = useParams();
    // console.debug(id,posts);

    useEffect(() => {
        setHistory(window.location);
    }, [location]);

    useEffect(() => {
        // Dispatch gePost initially
        dispatch(getSelectedPost(id));

        // Set interval to dispatch gePost every 5 seconds
        const intervalId = setInterval(() => {
            dispatch(getSelectedPost(id));
        }, 5000); // 5000 milliseconds = 5 seconds

        // Cleanup function to clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [dispatch]); // Dependency array with dispatch as dependency
    return (
        <div className=" community">
            <div className="container-fluid ">
                <div className="row m-0">
                    <div className="col-md-3  sidebarleft p-0 ml-auto centerPage d-none d-md-block">
                        <Profile user={auth} />
                        <TrendingNews user={auth} />
                        <SuggestedFreinds auth={auth} length={3} />
                    </div>
                    <div className="col-md-6 p-0 mx-auto centerPage">
                        <div className="mx-md-4" history>
                            <PostBlog />
                            {posts ? (
                                <Postcards
                                    isSelected={true}
                                    item={posts}
                                    key={posts.id}
                                />
                            ) : (
                                <PostLoader length={1} />
                            )}
                        </div>
                    </div>
                    <div className="col-md-3  p-0 mr-auto centerPage d-none d-md-block">
                        <TrendForYou user={auth} length={8} />
                        <FixturesSub user={auth} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectedPost;
