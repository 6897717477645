import React from "react";
import HeroVideo from "./components/HeroVideo";
import FeelPassion from "./components/FeelPassion";
import TodayFixture from "./components/TodayFixture";
import AboutUs from "./components/AboutUs";
import Talents from "./components/Talents";
import TrendingTopics from "./components/TrendingTopics";
import SEO from "../../components/SEO";

function LandingPage() {
    return (
        <div className="landingPage">
            <SEO
                title={"Soccernity"}
                description={
                    "Soccernity is a community of football enthusiasts that brokers social networking, banter, live scores, fixtures, news, and opportunities for football talents to be discovered and become football professionals."
                }
                link={"/"}
            />
            <HeroVideo />
            <FeelPassion />
            <TodayFixture />
            <AboutUs />
            <Talents />
            <TrendingTopics />
        </div>
    );
}

export default LandingPage;
