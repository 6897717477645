import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getSingleBlog } from "../../../../store/Blog";
import { toast } from "react-toastify";
import { addReplyReq } from "../../../../utils/request";
import { ModalClose } from "../../../../utils/modalTriger";

function RepliesForm({ item, comment_id, at_user, user }) {
    const dispatch = useDispatch();
    const queryId = window.location.pathname.slice(6);
    const [value, setformValue] = useState({
        body: at_user ? `@${item?.user?.username} ` : "",
    });
    const [isload, setLoading] = useState(false);
    const comment_input = document.querySelector("#body");

    const addValue = (e) => {
        setformValue({ ...value, [e.target.name]: e.target.value, comment_id });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        // setError(null)
        const res = await addReplyReq(value);
        if (res && res.data) {
            toast.success(res.data?.message);
            ModalClose(`reply${item?.id}`);

            comment_input.value = "";
            setLoading(false);

            dispatch(getSingleBlog(queryId));
        } else if (res && res?.response) {
            toast.error(res?.response?.data?.message);
            setLoading(false);
            if (res?.response?.data?.message === "Unauthenticated.") {
                window.location.href = "/auth/signin";
            }
        } else {
            toast.info("Oops something went wrong");
            setLoading(false);
        }
    };
    return (
        <div
            className="modal fade replyForm"
            id={`reply${item?.id}`}
            tabindex="-1"
            role="dialog"
            aria-labelledby="modelTitleId"
            aria-hidden="true"
        >
            <div className="modal-dialog data-reply modal-lg" role="document">
                <div className="modal-content mt-5">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Reply To {item?.user?.username} comment{" "}
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body p-4">
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="form-group">
                                <textarea
                                    name="body"
                                    id="body"
                                    cols="30"
                                    rows="10"
                                    className="form-control"
                                    placeholder="Enter your message here"
                                    onChange={(e) => addValue(e)}
                                    value={value?.body}
                                    autofocus
                                    required
                                ></textarea>
                                {/* <small id="emailHelpId" className="form-text text-muted">Help text</small> */}
                            </div>
                            <div className="form-group mb-0">
                                <button
                                    className="btn commentbtn "
                                    disabled={isload === true ? true : false}
                                >
                                    {isload ? "Replying..." : "Reply"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RepliesForm;
