import React from "react";
import moment from "moment";
import { Badge } from "primereact/badge";
import { useNotificationTypes } from "../../../hooks/useNotificationTypes";

function NotificationCard({ item }) {
    const notifyLink = useNotificationTypes();
    // console.debug(item?.type);
    return (
        <div
            className="notification-card bg-white mt-2 zoom link"
            onClick={() => notifyLink(item?.type, item)}
        >
            <div className="notification-image">
                <img
                    src={item?.creator?.profile?.profile_photo}
                    alt=""
                    className="border"
                />
            </div>
            <div className="notification-content">
                <div className="notification-title">
                    {item?.title}
                    {item?.status === "unread" && (
                        <Badge
                            value="new"
                            style={{
                                background: "#62e562",
                                padding: "0px .3rem",
                                marginLeft: ".4rem",
                            }}
                        />
                    )}
                </div>
                <div className="notification-description">
                    {item?.description}
                </div>
                <div className="notification-time text-right">
                    {moment(item?.created_at).fromNow()}
                </div>
            </div>
        </div>
    );
}

export default NotificationCard;
