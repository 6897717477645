 
import {   useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SidbarLoaders from "../../../../Loaders/SidbarLoaders";

function Profile() {
    // const dispatch = useDispatch()

    const auth = useSelector((state) => state.auth.user);
    const followers = useSelector((state) => state.community.followers?.length);
    const following = useSelector(
        (state) => state.community.followings?.length
    );
    const posts = useSelector(
        (state) =>
            state.community?.posts?.filter(
                (data) => data?.user?.id === auth?.id
            )?.length
    );


    if (!auth) return <SidbarLoaders />;
    return (
        <div className="profile">

            <div className="card w-100 ">
                <img
                    className="card-img-top coverImageProfile"
                    src={auth?.profile?.cover_photo ?? "/images/profilebg.png"}
                    alt="Card"
                />

                <div className="card-body">
                    <img
                        className="img-top-profile profile-x-avatar"
                        src={auth?.profile?.profile_photo}
                        alt="Card"
                    />

                    <div className="card-content ">
                        <h4 className="card-title">
                            {auth?.profile?.first_name
                                ? auth?.profile?.first_name +
                                  " " +
                                  auth?.profile?.last_name
                                : auth?.username}
                        </h4>
                        <p className="card-text">@{auth?.username}</p>
                    </div>
                    <hr />
                    <div className="card-content-2">
                        <p className="card-bio">{auth?.profile?.bio}</p>
                        <p className="card-location">
                            <img src="/images/location.png" alt="" />{" "}
                            {auth?.profile?.location?.slice(0, 50)}
                        </p>
                    </div>
                    <hr />
                    <div className="card-content-2">
                        <div className="row m-0">
                            <div className="col-4 bt">
                                <span className="firm">Followers</span>
                                <p className="details">{followers}</p>
                            </div>
                            <div className="col-4 bt">
                                <span className="firm">Posts</span>
                                <p className="details">{posts}</p>
                            </div>
                            <div className="col-4">
                                <span className="firm">Following</span>
                                <p className="details">{following}</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center  p-4">
                        <Link
                            to={`/profile?user=${auth?.email}`}
                            className="link-btn btn"
                        >
                            View profile
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
