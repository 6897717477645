import React,{useState} from 'react'
import Truncate from "react-truncate-html";

function BodyPost({body}) {
    const [isTruncated, setIsTruncated] = useState(false);
    const maxLength = 200;
    // useEffect(() => {
    //     if(body?.length > maxLength) setIsTruncated(true)
    // }, [])
    const [key, setKey] = useState(0);
    const toggleTruncate = () => {
        setIsTruncated(!isTruncated);
        setKey(key + 1); // Increment key to force remount
    };
  return (
   <React.Fragment key={key}>
    <Truncate
    key={key}
      lines={isTruncated===false ? 6: Infinity} // Show up to 6 lines initially
      width={300} // Adjust width as needed
      dangerouslySetInnerHTML={{
          __html: body,
      }}
      breakWord={true}
      responsive={true}
  />
        {body?.length > maxLength && (
      <button onClick={toggleTruncate} className="btn btn-sm shadow-none border-none">
             {isTruncated===false ? "Show More":"Show Less"}
         </button> )}

   </React.Fragment>
  )
}

export default BodyPost
