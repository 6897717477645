import CommentsCard from "./CommentsCard";
import CommentForm from "./CommentForm";
import { Link, useLocation } from "react-router-dom";

function CommentContainer({ item, user }) {
    const isPage = useLocation().pathname.match("post");
    // console.debug(isPage);
    return (
        <div className="CommentContainer pl-4 mb-4">
            <hr />
            <CommentForm item={item} key={item} user={user} />
            {/* load all the comments */}
            {/* {isPage} */}
            {item?.comments?.length ? (
                <div className="mt-3 py-2 pl-3 ">
                    {item?.comments?.map((comments, key) => (
                        <CommentsCard item={comments} key={key} user={user} />
                    ))}
                </div>
            ) : (
                <small className="d-flex justify-content-center align-items-center mt-3">
                    <Link to={`/post/${item?.id}`} className="text-primary ">
                        {!isPage ? "View all comments" : "No comments"}
                    </Link>
                </small>
            )}
        </div>
    );
}

export default CommentContainer;
