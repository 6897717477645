export const fixturesArray = [
    {
        home: {
            logo: "/images/chelsea.png",
            score: 4,
            name: "chelsea"
        },
        away: {
            logo: "/images/liverpool.png",
            score: 0,
            name: "liverpool"

        }
    },
    {
        home: {
            logo: "/images/chelsea.png",
            score: 4,
            name: "chelsea"

        },
        away: {
            logo: "/images/liverpool.png",
            score: 0,
            name: "liverpool"
        }
    },
    {
        home: {
            logo: "/images/chelsea.png",
            score: 3,
            name: "chelsea"

        },
        away: {
            logo: "/images/liverpool.png",
            score: 0,
            name: "liverpool"

        }
    },
    {
        home: {
            logo: "/images/chelsea.png",
            score: 4,
            name: "chelsea"

        },
        away: {
            logo: "/images/liverpool.png",
            score: 6,
            name: "liverpool"

        }
    },
    {
        home: {
            logo: "/images/chelsea.png",
            score: 4,
            name: "chelsea"

        },
        away: {
            logo: "/images/liverpool.png",
            score: 2,
            name: "liverpool"

        }
    },
    {
        home: {
            logo: "/images/chelsea.png",
            score: 2,
            name: "chelsea"

        },
        away: {
            logo: "/images/liverpool.png",
            score: 0,
            name: "liverpool"

        }
    },
]



export const aboutUs = [
    {
        icon: "/images/game-icons_soccer-field.png",
        description: `<p>Soccernity(soccer community) as the name implies, is a community of football enthusiasts that brokers social networking and banter, live scores, fixtures, and news, and at the same time, creates opportunities for football talents to be discovered and become football professionals.</p>
<p>
As an added advantage, lovers of football that are interested in picking careers in the football industry will also get access to career support and life changing opportunities to potentially earn a living within the football industry.Most importantly, as fans, we are united, and our voices are heard as one.
</p>


<p>
Our application is a digital networking platform for football fans and enthusiasts, and a technical scouting platform that has a wide range scouting of talents with the implementation of AI and ML.It solves the problem of limited scouting coverage in the football industry, which often results in talented footballers being overlooked.By using AI and ML to identify talents in developing countries, Soccernity is creating opportunities for aspiring footballers who might not have had access to traditional scouting methods.
</p>
        `
    },
    {
        icon: "/images/system-uicons_location.png",
        description: `<p>
Our company is a startup registered and incorporated in England, UK with registration number 14817464.

        </p >


        `
    },
    {
        icon: "/images/tabler_play-football.png",
        description: `<p>
Our goal is to facilitate a wide range scouting of talents with the implementation of AI and ML.We discovered there is a gap in the football scouting industry as the largest percentage of scouts don't visit developing countries to find talents but focus mostly on Europe and a few other countries.
    </p>
<p>
As a result of this, Soccernity aims to discover talents in developed counties and developing countries as well.We are bridging that gap with our wide coverage scouting and creating opportunities for talented footballers who might not have been discovered otherwise.At the same time, we are creating an avenue for football enthusiasts to earn a living within the football industry.

</p>


<p>
We are using technological innovation (in the implementation of Artificial Intelligence and Machine Learning) to enable a large - scale scouting of football talents.This approach enables us to discover talents in developing countries, which are often overlooked by traditional scouting methods.By using AI and ML, we can gather data on a large scale and use it to identify potential talents that might have been missed otherwise.


</p>
        `
    },
]




export const talents = [
    {
        title: "Scounting | Kenya Football Association",
        date: Date.now(),
        videoUrl: "https://www.youtube.com/embed/1fkYP6s_mBE"
    },
    {
        title: "Scounting | Kenya Football Association",
        date: Date.now(),
        videoUrl: "https://www.youtube.com/embed/tfY-I-sNQnQ"
    },
    {
        title: "Scounting | Kenya Football Association",
        date: Date.now(),
        videoUrl: "https://www.youtube.com/embed/UsATG-UMkbQ"
    },
    {
        title: "Scounting | Kenya Football Association",
        date: Date.now(),
        videoUrl: "https://www.youtube.com/embed/tfY-I-sNQnQ"
    },
    {
        title: "Scounting | Kenya Football Association",
        date: Date.now(),
        videoUrl: "https://www.youtube.com/embed/tfY-I-sNQnQ"
    },
    {
        title: "Scounting | Kenya Football Association",
        date: Date.now(),
        videoUrl: "https://www.youtube.com/embed/_-RhAbebMY0"
    },
]


