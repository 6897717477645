import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getChatReq, getLastConvesationReq } from "../../utils/request";


const ischatUser = localStorage.getItem("ischatUser")
export const getChat = createAsyncThunk(
    'user/getChat',
    async (data) => {
        const res = await getChatReq(data);
        return res.data?.conversation;
    }
)
export const getLastConvesation = createAsyncThunk(
    'user/getLastConvesation',
    async (data) => {
        const res = await getLastConvesationReq(data);
        return res.data?.recentMessages;
    }
)
export const chat = createSlice(
    {
        name: "chat",
        initialState: {
            ischatUser: ischatUser,
            chats: "",
            lastConversation: "",
            toggle: false
        },
        reducers: {
            openIschat: (state, action) => {
                localStorage.setItem("ischatUser", action.payload)
                state.ischatUser = action.payload
            },
            setformValue: (state, action) => {
                const data = action.payload;
                state.formValue = { ...state.formValue, data };
            },
            chatToggle: (state, action) => {
                console.log(action.payload)
                state.toggle = action.payload;
            },

        },
        extraReducers: (builder) => {
            builder.addCase(getChat.fulfilled, (state, action) => {
                state.chats = action.payload
            })
            builder.addCase(getLastConvesation.fulfilled, (state, action) => {
                state.lastConversation = action.payload
            })
        }

    });


export const { openIschat, setformValue, setAuth, chatToggle } = chat.actions;

export default chat.reducer;
