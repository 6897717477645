import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import CreateBanter from "./CreateBanter";

const BantForm = ({ auth }) => {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <button
                className="btn btn-create-topic btn-blue mb-4"
                type="button"
                onClick={() => setVisible(true)}
            >
                Create New Bant
            </button>
            <Dialog
                header=""
                visible={visible}
                style={{ minWidth: "50vw", borderRadius: 12 }}
                closable={true}
                onHide={() => setVisible(false)}
                headerClassName="p-0"
                focusOnShow={false}
            >
                <div className="modal-body cratePostTab p-0  ">
                    {/* pull in tab */}
                    <ul
                        className="nav nav-pills   mb-3 border-bottom "
                        id="pills-tab"
                        role="tablist"
                    >
                        <li className="nav-item">
                            <a
                                className="nav-link active link"
                                id="pills-home-tab"
                                data-toggle="pill"
                                href="#pills-home"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                            >
                                Create a Banter
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                        >
                            <CreateBanter
                                auth={auth}
                                changeVisibility={(e) => setVisible(e)}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default BantForm;
