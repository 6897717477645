import React from "react";
import { useLocation } from "react-router-dom";

function PushToTop() {
    const scrollToBottom = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const path = useLocation().pathname;
    const links = ["/profile", "/"];
    if (links.includes(path))
        return (
            <div className="PushToTop shadow" onClick={() => scrollToBottom()}>
                <i className="fa fa-arrow-up" aria-hidden="true"></i>
            </div>
        );
}

export default PushToTop;
