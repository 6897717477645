import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

function VerificationCompletion() {
    // const email = localStorage.getItem('email')
    const [counter, setCounter] = useState(60)
    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter])
    return (
        <div className='auth signin text-center mt-5  confettiDesign'>
           <div class="card text-left p-0  border-0 ">
             {/* <img class="card-img-top" src="holder.js/100px180/" alt="s"/> */}
             <div class="card-body">
             <div className="verification-completion-message">
                <h2>Congratulations !</h2>
                <p>You have successfully completed the verification process.</p>
                {/* <p>Your commitment to security and integrity is truly commendable.</p> */}

                <div className="confetti-container">
        <div className="confetti red"></div>
        <div className="confetti blue"></div>
        <div className="confetti green"></div>
        <div className="confetti yellow"></div>
        <div className="confetti orange"></div>
        <div className="confetti purple"></div>
        <div className="confetti pink"></div>
      </div>
                </div>
             </div>
           </div>


            <div className="extra text-center">
               Login now? <Link to="/auth/signin">click here</Link>
            </div>


        </div>
    )
}

export default VerificationCompletion
