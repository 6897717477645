import React from "react";
// import FilterByLeague from "./components/FilterByLeague";
// import Fixtures from "./components/Fixtures";
// import { config } from "../../utils/config";
import Footer from "../../layouts/Layout/Footer";
const LiveScore = () => {


    return (
        <div className="livescore_root">
            {/* <div className="row m-0">
                <div className="col-md-4 p-0 sidepage">
                    <FilterByLeague />
                </div>
                <div className="col-md-8 ">
                    <Fixtures/>
                </div>
            </div> */}
            <div className="container-fluid">
                <iframe
                    title="Sport"
                    src={"/widget.html"}
                    width="100%"
                    height="100vh"
                    frameBorder="0"
                    style={{ width: "100%", height: "100vh", border: "0" }}
                ></iframe>
                <div className="reload"></div>
            </div>
            <Footer />
        </div>
    );
};

export default LiveScore;
