/* eslint-disable */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import Blog from "./pages/Blog";
import SingleBlog from "./pages/SingleBlog";
import Contact from "./pages/Contact";
import Signin from "./pages/Auth/Signin";
import Signup from "./pages/Auth/Signup";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import AuthLayout from "./layouts/AuthLayout";
import Terms from "./pages/TermsAndPolicy/Terms";
import Policy from "./pages/TermsAndPolicy/Policy";
import LiveScore from "./pages/liveScore";
import MatchDetails from "./pages/liveScore/components/MatchDetails/index";
import Community from "./pages/Community";
import Layout from "./layouts/Layout";
import Verify from "./pages/Auth/Verify";
import ProtectedRoutes from "./Middleware/ProtectedRoutes";
import ShowProfile from "./pages/Community/components/Profile/ShowProfile";
import AppLoader from "./AppLoader";
import CompleteProfile from "./pages/Auth/CompleteProfile";
import './loader.js'
import CommingSoon from "./layouts/SpecailLayout/CommingSoon";
// import Messages from './pages/Chats/components/Messages';
import PageNotFound from "./layouts/SpecailLayout/PageNotFound";
import PushToTop from './components/PushToTop/PushToTop';
import ChatContainerDesktop from './pages/Chats/ChatContainerDesktop';
import ChatContainerMobile from "./pages/Chats/ChatContainerMobile";
import LeaderBoard from "./pages/LeaderBoard";
import SingleEntry from "./pages/LeaderBoard/SingleEntry";
import Banter from "./pages/Banter";
import SingleBanter from "./pages/Banter/SingleBanter";
import LandingPage from "./pages/LandingPage";
import "./utils/websocket.js";
import { ToastContainer } from "react-toastify";
import VerificationCompletion from "./pages/Auth/VerificationCompletion.jsx";
import { useSelector } from "react-redux";
import FirstLoading from "./components/Loader/FirstLoading.jsx";
import SelectedPost from "./pages/Community/selectedPost/index.jsx";
import SettingsLayout from "./pages/Settings/index.jsx";
import TwoFA from './pages/Settings/components/TwoFA.jsx';
import SearchPage from "./pages/SearchPage/index.jsx";
import TwoFactorAuth from "./pages/Auth/TwoFactorAuth.jsx";

function App() {
    AppLoader()
    const notificationCount = useSelector(
        (state) => state.notification?.notificationCount
    );
    // console.debug(notificationCount)
    // const details = true;
    // if (!notificationCount)
    //   return   <FirstLoading/>
    // else
    return (
        <Router>
            <Routes>
                {/* Private Route start*/}
                {/* Private Route end*/}
                {/* Public Route start*/}
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:id" element={<SingleBlog />} />
                    <Route path="/sport" element={<LiveScore />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/policy" element={<Policy />} />
                </Route>

                <Route path="/" element={<ProtectedRoutes />}>
                    <Route path="/community" element={<Community />} />
                    <Route path="/post/:id" element={<SelectedPost />} />
                    <Route path="/leaderboard" element={<LeaderBoard />} />
                    <Route path="/leaderboard/:id" element={<SingleEntry />} />
                    <Route path="/banter" element={<Banter/>} />
                    <Route path="/banter/:id" element={<SingleBanter />} />
                    <Route path="/profile" element={<ShowProfile />} />
                    <Route path="/chats" element={<ChatContainerDesktop />} />
                    <Route path="/chat" element={<ChatContainerMobile />} />
                    <Route path="/settings" element={<SettingsLayout />}/>
                    <Route path="/search" element={<SearchPage />}/>
                </Route>


                <Route path="/match-details" element={<MatchDetails />} />
                {/* Public Route end*/}
                {/* Auth Start */}
                <Route path="/auth" element={<AuthLayout />}>
                    <Route path="signin" element={<Signin />} />
                    <Route path="signup" element={<Signup />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route path="verify" element={<Verify />} />
                    <Route path="complete-profile" element={<CompleteProfile />} />
                    <Route path="verification-completion" element={<VerificationCompletion />} />
                    <Route path="2fa" element={<TwoFactorAuth />} />

                </Route>
                {/* <Route path="verified" element={<ResetPassword />} /> */}

                {/* Auth end */}

                {/* 404 pages */}
                <Route path='*' element={<PageNotFound />} />

            </Routes>
            <PushToTop />
            <ToastContainer
                position="top-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

        </Router>
    );
}

export default App;
