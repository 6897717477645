import React from 'react'
import { Helmet } from 'react-helmet'

function SEO({ title, description, link }) {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <link rel="description" content={description} />
            <link rel="canonical" href={link} />
        </Helmet>

    )
}

export default SEO

