import React, { useEffect } from "react";
import LoaderBoardInfoPage from "./components/LoaderBoardInfoPage";
import Entry from "./components/Entry";
import { useDispatch, useSelector } from "react-redux";
import { getRank ,getLastWeekTop, getLeaderBoard, getLeaderBoardParticipateCheck } from "../../store/LeaderBoard";
// import { getRankReq } from "../../utils/request";

function LeaderBoard() {
    /* eslint-disable */
    const dispatch = useDispatch()

    const { leaderboard_challange, current_leaderBoard } = useSelector(
        (state) => state.leaderboard
    );


    useEffect(() => {
        dispatch(getLeaderBoardParticipateCheck())
        dispatch(getLeaderBoard())
        dispatch(getLastWeekTop())
        dispatch(getRank())

        // Set interval to dispatch gePost every 5 seconds
        const intervalId = setInterval(() => {
            dispatch(getRank())
            dispatch(getLeaderBoardParticipateCheck())
            dispatch(getLeaderBoard())
        }, 5000); // 5000 milliseconds = 5 seconds

        // Cleanup function to clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [dispatch]); // Dependency array with dispatch as dependency



    // console.debug(typeof leaderboard_challange);



    return (
        <div className="leaderboard">
            {/* leaderBoard Page */}

            {leaderboard_challange?.length
            &&
            current_leaderBoard.phase !== 'initial'
            ? (
                <Entry
                    leaderboard_challange={leaderboard_challange}
                    current_leaderBoard={current_leaderBoard}
                />
            ) : (
                <LoaderBoardInfoPage />
            )}
        </div>
    );
}

export default LeaderBoard;
