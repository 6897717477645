import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import ChangePassword from "./components/ChangePassword";
import ChangeUserDetails from "./components/ChangeUserDetails";
import DeactivateAccount from "./components/DeactivateAccount";
import TwoFA from "./components/TwoFA";
import PrivacySafety from "./components/PrivacySafety";
import Notification from "./components/Notification";
import Preference from "./components/Preference";

export default function index() {
    return (
        <div className="container-fluid my-5 pt-5">
            <h3 className="text-secondary">
                <b>Settings</b>
            </h3>
            <TabView>
                <TabPanel header="Account">
                    {/* changePassword */} <ChangePassword />
                    {/* changeuserDetails */} <ChangeUserDetails />
                    {/* deactivate account */} <DeactivateAccount />
                </TabPanel>
                <TabPanel header="Security">
                    {/* 2fa */} <TwoFA />
                </TabPanel>
                <TabPanel header="Privacy and Safety">
                    {/* Privacy */} <PrivacySafety />
                </TabPanel>
                <TabPanel header="Notification">
                    {/* Notification */} <Notification />
                </TabPanel>

                <TabPanel header="Preference">
                    {/* Preference */} <Preference />
                </TabPanel>
            </TabView>
        </div>
    );
}
