import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import FirstLoading from "../../../components/Loader/FirstLoading";
import { useNavigate } from "react-router-dom";
// import { useDispatch } from 'react-redux';
// import { getChat } from '../../../store/Chat/index';
// import { getProfile } from '../../../store/Auth';
import SelectChat from "../components/SelectChat";
// import MessageInfo from '../../../components/Notifications/MessageInfo';

function ChatsBox() {
    const auth = useSelector((state) => state.auth?.user);
    const lastConversation = useSelector(
        (state) => state.chat?.lastConversation
    );
    // const users = useSelector((state) => state.community?.users);
    const followings = useSelector((state) => state.community?.followings);
    const navigate = useNavigate();
    const [search, setSearch] = useState();
    // const dispatch = useDispatch();
    const navigateLink = (username, id) => {
        // dispatch(getProfile(item?.sender?.username))
        // dispatch(getChat(item?.sender?.id))
        navigate(`/chats?user=${username}&id=${id}`);
    };
    const navigateLinkM = (username, id) => {
        navigate(`/chat?user=${username}&id=${id}`);
    };
    const filter = (data) => {
        const filtered = data.filter((data) => {
            if (
                data.sender?.id !== auth?.id &&
                (data.sender?.profile?.first_name
                    ?.toLowerCase()
                    ?.match(search) ||
                    data.sender?.profile?.last_name
                        ?.toLowerCase()
                        ?.match(search))
            ) {
                return data;
            } else {
                return false;
            }
        });
        return filtered;
    };
    const filterUsers = (data) => {
        const filtered = data.filter((data) => {
            if (
                data?.id !== auth?.id &&
                (data?.profile?.first_name?.toLowerCase()?.match(search) ||
                    data?.profile?.last_name?.toLowerCase()?.match(search))
            ) {
                return data;
            } else {
                return false;
            }
        });
        return filtered;
    };

    const [dataFromChild, setDataFromChild] = useState("users-chats");

    // Callback function to receive data from the child component
    const receiveDataFromChild = (data) => {
        setDataFromChild(data);
    };

    if (!lastConversation || !followings) return <FirstLoading />;
    return (
        <div className="d-contents">
            <div className={`chat-users `}>
                <div className="minihead d-md-none">
                    <div
                        className="back "
                        onClick={() => navigate("/community")}
                    >
                        <img src="/images/back.png" alt="" />{" "}
                        <span>Messages</span>
                    </div>
                </div>

                <div className="search-filter">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search Chats"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>

                {/* desktop view */}
                <SelectChat sendDataToParent={receiveDataFromChild} />

                {/* chats */}
                <ul className="d-none d-md-block border-top p-0 m-0">
                    {lastConversation &&
                        dataFromChild === "users-chats" &&
                        filter(lastConversation)?.map((item, key) => (
                            <li
                                key={key}
                                onClick={() =>
                                    navigateLink(
                                        item.sender?.username,
                                        item.sender?.id
                                    )
                                }
                            >
                                <div className="imgB">
                                    {" "}
                                    <img
                                        src={
                                            item.sender?.profile
                                                ?.profile_photo ??
                                            "/images/chat-profile.png"
                                        }
                                        alt=""
                                        className="imgAvatarsBoard shadow-sm"
                                    />
                                </div>
                                <div className="user">
                                    {" "}
                                    <h2>
                                        <span>
                                            {" "}
                                            {item?.sender?.profile
                                                ?.first_name ??
                                                item?.username}{" "}
                                            {item?.sender?.profile?.last_name}
                                        </span>
                                    </h2>
                                    <p> {item?.body}</p>
                                </div>
                                <div className="time">
                                    <b className="time-up">
                                        {moment(item?.created_at).fromNow()}
                                    </b>
                                </div>
                            </li>
                        ))}
                    {/* followings */}
                    {followings &&
                        dataFromChild === "followers-chats" &&
                        filterUsers(followings)?.map((item, key) => (
                          <li
                         key={key}
                         onClick={() =>
                             navigateLink(item?.username, item?.id)
                         }
                     >
                         <div className="imgB">
                             {" "}
                             <img
                                 src={
                                     item?.profile?.profile_photo ??
                                     "/images/chat-profile.png"
                                 }
                                 alt=""
                                 className="imgAvatarsBoard shadow-sm"
                             />
                         </div>
                         <div className="user">
                             {" "}
                             <h2>
                                 <span>
                                     {" "}
                                     {item?.profile?.first_name ??
                                         item?.username}{" "}
                                     {item?.profile?.last_name}
                                 </span>
                             </h2>
                             <p>
                                 {" "}
                                 {item?.profile?.bio ?? "Just Joining"}
                             </p>
                         </div>
                     </li>
                        ))}
                </ul>


{/* mobile */}
                <ul className="d-block d-md-none border-top p-0 m-0">
                    {lastConversation &&
                        dataFromChild === "users-chats" &&
                        filter(lastConversation)?.map((item, key) => (
                            <li
                                key={key}
                                onClick={() =>
                                    navigateLinkM(
                                        item.sender?.username,
                                        item.sender?.id
                                    )
                                }
                            >
                                <div className="imgB">
                                    {" "}
                                    <img
                                        src={
                                            item.sender?.profile
                                                ?.profile_photo ??
                                            "/images/chat-profile.png"
                                        }
                                        alt=""
                                        className="imgAvatarsBoard shadow-sm"
                                    />
                                </div>
                                <div className="user">
                                    {" "}
                                    <h2>
                                        <span>
                                            {" "}
                                            {item?.sender?.profile
                                                ?.first_name ??
                                                item?.username}{" "}
                                            {item?.sender?.profile?.last_name}
                                        </span>
                                    </h2>
                                    <p> {item?.body}</p>
                                </div>
                                <div className="time">
                                    <b className="time-up">
                                        {moment(item?.created_at).format(
                                            "h:mm a"
                                        )}
                                    </b>
                                </div>
                            </li>
                        ))}
                    {/* followings */}
                    {followings &&
                        dataFromChild === "followers-chats" &&
                        filterUsers(followings)?.map((item, key) => (
                            <li
                                key={key}
                                onClick={() =>
                                    navigateLinkM(item?.username, item?.id)
                                }
                            >
                                <div className="imgB">
                                    {" "}
                                    <img
                                        src={
                                            item?.profile?.profile_photo ??
                                            "/images/chat-profile.png"
                                        }
                                        alt=""
                                        className="imgAvatarsBoard shadow-sm"
                                    />
                                </div>
                                <div className="user">
                                    {" "}
                                    <h2>
                                        <span>
                                            {" "}
                                            {item?.profile?.first_name ??
                                                item?.username}{" "}
                                            {item?.profile?.last_name}
                                        </span>
                                    </h2>
                                    <p>
                                        {" "}
                                        {item?.profile?.bio ?? "Just Joining"}
                                    </p>
                                </div>
                            </li>
                        ))}
                </ul>
            </div>
            {/* followings
                {((filterUsers(followings)?.length <=0  && dataFromChild==='users-chats')|| (filter(lastConversation)?.length <=0 && dataFromChild==='users-chats'))   && <li>
                <MessageInfo message="No User Found!"/>
                </li>} */}

        </div>
    );
}

export default ChatsBox;
