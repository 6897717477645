import { configureStore } from "@reduxjs/toolkit";
import livescores from "./LiveScores";
import general from "./General";
import blog from "./Blog";
import auth from "./Auth";
import community from "./Community";
import chat from './Chat';
import leaderboard from './LeaderBoard';
import banter from './Banter';
import notification from './Notification';
import draftSlice from "./Draft";

export const store = configureStore({
    reducer: {
        livescores,
        general,
        blog,
        community,
        auth,
        chat,
        leaderboard,
        banter,
        notification,
        draftSlice

    }
});
