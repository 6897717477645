import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { getDraftReq } from "../../utils/request";


export const getDraft = createAsyncThunk(
    'draft/getDraft',
    async () => { 
        const res = await getDraftReq();  
        return res.data;
    }
)
export const draftSlice = createSlice(
    {
        name: "draft",
        initialState: {},
        draft: "",
        reducers: {
           
        },
        extraReducers: (builder) => {
            builder.addCase(getDraft.fulfilled, (state, action) => {
                state.user = action.payload;
            })
        },
    });

 
export default draftSlice.reducer;
