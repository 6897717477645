import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Header from "../layouts/Layout/Header";

const useAuth = () => {
    const isAuth = localStorage.getItem("token");
    if (!isAuth || isAuth === null) {
        return false;
    }
    return true;
};

const useProfile = () => {
    const isAuth = localStorage.getItem("userProfile");
    if (!isAuth || isAuth === null) {
        return false;
    }
    return true;
};

function ProtectedRoutes() {
    const auth = useAuth();
    const noProfile = useProfile();

    const SystemCheck = () => {
        if (auth) {
            if (!noProfile) return <Navigate to={"/auth/complete-profile"} />;
            else return <Outlet />;
        } else {
            return <Navigate to={"/auth/signin"} />;
        }
    };
    return (
        <React.Fragment>
            <Header />
            <SystemCheck />
        </React.Fragment>
    );
}

export default ProtectedRoutes;
