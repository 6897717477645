import React, { useState } from 'react'
import Hero from '../Blog/components/Hero'
import { postContactReq } from '../../utils/request';
import { toast } from 'react-toastify';

function Contact() {
    const [data, setData] = useState();
    const initalValue = {
        name: "",
        subject: "",
        email: "",
        message: "",
    }
const [value, setformValue] = useState(initalValue);

const [isload, setLoading] = useState(false);
const addValue = (e) => {
    setformValue({ ...value, [e.target.name]: e.target.value })
}
const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    // setError(null)
    const res = await postContactReq(value);
    if (res && res.data) {
        toast.success("Feedback will be given within 24hours!")
        setLoading(false)
        setformValue(initalValue)

    }
    else if (res && res?.response) {
        toast.error(res?.response?.data?.message)
        setLoading(false)
    }
    else {
        toast.info('Oops something went wrong')
        setLoading(false)
    }

}
return (
    <div className='contact '>
        <Hero />
        <h2 className='conTxt'>Contact Us</h2>
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="container py-5 col-xl-6 col-md-10 mx-auto">
                <div className="form-group">
                    <input type="text" className="form-control" name="name" id="" aria-describedby="emailHelpId" placeholder="Name"
                        onChange={(e) => addValue(e)}
                        value={value.name}

                        required />
                    {/* <small id="emailHelpId" className="form-text text-muted">Help text</small> */}
                </div>
                <div className="form-group">
                    <input type="email" className="form-control" name="email" id="" aria-describedby="emailHelpId" placeholder="Email"
                        onChange={(e) => addValue(e)}
                        required
                        value={value.email}
                    />
                    {/* <small id="emailHelpId" className="form-text text-muted">Help text</small> */}
                </div>
                <div className="form-group">
                    <select id="" className='form-control' name="subject"
                        onChange={(e) => {
                            const subject = e.target.value
                            setData(subject)
                            if (subject !== "Others") addValue(e)
                        }
                        }
                    >
                        <option >Technical issues</option>
                        <option >Editorial Complaints</option>
                        <option >Data / Livescores Issues</option>
                        <option >Suggestions</option>
                        <option >Enquiries/Feedback</option>
                        <option >Others</option>
                    </select>
                    {/* <small id="emailHelpId" className="form-text text-muted">Help text</small> */}
                </div>
                {data === "Others" && <div className="form-group">
                    <input type="text" className="form-control" name="subject" id="" aria-describedby="emailHelpId" placeholder="Subject"
                        onChange={(e) => addValue(e)}
                        required
                        value={value.subject}

                    />
                    {/* <small id="emailHelpId" className="form-text text-muted">Help text</small> */}
                </div>}

                <div className="form-group">
                    <textarea name="message" id="" cols="30" rows="5" className='form-control contactInput' placeholder='Enter your message here'
                        onChange={(e) => addValue(e)}
                        value={value.message}

                        required
                    ></textarea>
                    {/* <small id="emailHelpId" className="form-text text-muted">Help text</small> */}
                </div>
                <div className="form-group mb-0  text-center">
                    <button className='loadMore-btn' disabled={isload === true ? true : false} type='submit' >  {isload ? 'Processing...' : "Submit"}</button>

                </div>
            </div>
        </form>
        {/* <ToastContainer /> */}

    </div>
)
}

export default Contact
