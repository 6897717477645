import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import video from "./heroVideo.mp4";

function HeroVideo() {
    // const [videoUrl] = useState("/video/heroVideo.mp4");
    const [playing, setPlaying] = useState(false);
    // const [audio] = useState(new Audio("/audio/Soccernity.mp3"));
    // const [isPlaying, setIsPlaying] = useState(false);
    useEffect(() => {
        // Set playing to true when the component mounts
        setTimeout(() => {
            setPlaying(true);
        }, 3000);

        // if (isPlaying) {
        //     audio.play();
        // }
    }, []); // Empty dependency array means this effect runs once after the initial render

    return (
        <div className="video-player-container">
            <ReactPlayer
                url={video}
                width="100%"
                height="100%"
                playing={playing}
                controls={true}
                onPlay={() => setPlaying(true)}
                onLoad={() => setPlaying(true)}
                // muted
                autoPlay={true}
            />
        </div>
    );
}

export default HeroVideo;
