import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;


window.Echo = new Echo({
    broadcaster: 'pusher',
    key: "sokkanity",
    cluster: 'mt1',
    wsHost: "api.soccernity.com",
    wsPort: 6001,
    disableStats: true,
    enabledTransports: ['ws'],
});

 