import CryptoJS from 'crypto-js';

const { REACT_APP_PASS_PHRASE } = process.env;//encryption algorithm code

export const encryptToken = (token) => {
    try {
        const encrypt = CryptoJS.AES.encrypt(token, REACT_APP_PASS_PHRASE).toString();
        localStorage.setItem("token", encrypt)
        return encrypt;
    } catch (error) {

    }
};

export const decryptToken = () => {
    try {
        const ciphertext = localStorage.getItem("token")
        const bytes = CryptoJS.AES.decrypt(ciphertext, REACT_APP_PASS_PHRASE);
        return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {

    }
};
