import React  from "react";

import PostForm from "./PostForm";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";

function PostBlog() {
    const auth = useSelector((state) => state.auth.user);
    // const [visible, setVisible] = useState(false);
// v
    return (
        <div className="postBox postBox2 border">
            <div className="row m-0">
                <div className="col-2 text-center p-0 ">
                    <Link to={`/profile?user=${auth?.email}`}>
                        {auth ? (
                            <img
                                src={auth?.profile?.profile_photo}
                                alt=""
                                className="profile-large-avatar ml-1"
                            />
                        ) : (
                            <Skeleton
                                shape="circle"
                                size="4rem"
                                className="m-2"
                            ></Skeleton>
                        )}
                    </Link>
                </div>

                <PostForm auth={auth}   />
            </div>
        </div>
    );
}

export default PostBlog;
