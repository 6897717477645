import React from 'react'
import { useEffect, useRef } from 'react';

function Messages({ chat, auth }) {
    const messageEl = useRef(null);
    // const [isRefresh,setIsrefresh] = useRef(null);

    useEffect(() => {
        if (messageEl ) {
            messageEl.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
    }, [messageEl])
    return (
        <div className={`chat-box-set Messages`} ref={messageEl}>
            {chat && chat?.map((item, key) => <div className={`chat-container row m-0 ${auth?.id === item?.receiver?.id ? 'sender-container' : 'reciever-container'}`}>
                <div className={`chat-message-set ${auth?.id === item?.receiver?.id ? 'sender' : 'reciever'}`}  >
                    {item?.body}
                </div>
            </div>)}
        </div>
    )
}

export default Messages
